import { Theme } from "@mui/material";

export const InputLabel = (theme: Theme) => {
  return {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          px: "5px",
        },
      },
    },
  };
};
