import { useContext, useLayoutEffect, useState } from "react";
import { UserRole } from "../@types/api";
import { userAuthContext } from "../context/userAuthContext";
import { Navigate } from "react-router-dom";

interface RoleRouteGuardProps {
  children: any;
  restrictedRoles: UserRole[];
}

export const RoleRouteGuard = ({
  children,
  restrictedRoles,
}: RoleRouteGuardProps) => {
  const { user } = useContext(userAuthContext);
  const [isRestricted, setIsRestricted] = useState(false);

  useLayoutEffect(() => {
    if (!user) {
      setIsRestricted(false);
      return;
    }

    const role = user?.role;
    const restrict = () => {
      setIsRestricted(true);
      return;
    };

    if (!role) {
      return restrict();
    }

    if (restrictedRoles.includes(role)) {
      return restrict();
    }

    setIsRestricted(false);
  }, [restrictedRoles, user]);

  if (isRestricted) return <Navigate to="404" />;

  return children;
};
