import { api } from ".";
import {
  StartupResponse,
  CrunchbaseOrganization,
  CreateUpdateStartupDto,
} from "../../@types/api";

export interface CreateUpdateStartupRequest {
  id: string;
  data?: CreateUpdateStartupDto;
}

interface GetStartupArgs {
  id: string;
  withoutErrorMessage?: boolean;
}

export const startupApi = api.injectEndpoints({
  endpoints: (build) => ({
    getStartupsListByFund: build.query<StartupResponse[], string>({
      query: (id: string) => ({ url: `/startup/fund/${id}` }),
      providesTags: ["StartupsListByFund"],
    }),
    createUpdateStartup: build.mutation<
      StartupResponse,
      CreateUpdateStartupRequest
    >({
      query: ({ id, data }) => ({
        url: `/startup/${id}`,
        data,
        method: "PATCH",
      }),

      invalidatesTags: ["StartupsListByFund"],
    }),
    getStartup: build.query<StartupResponse, GetStartupArgs>({
      query: ({ id, withoutErrorMessage }: GetStartupArgs) => ({
        url: `/startup/${id}`,
        withoutErrorMessage,
      }),
    }),
    getStartupMeta: build.query<CrunchbaseOrganization, string>({
      query: (id: string) => ({ url: `/startup/meta/${id}` }),
    }),
    removeStartup: build.mutation<StartupResponse["id"], StartupResponse["id"]>(
      {
        query: (id: string) => ({ url: `/startup/${id}`, method: "DELETE" }),
        invalidatesTags: ["Startup", "StartupsListByFund"],
      }
    ),
  }),
});

export const {
  useGetStartupsListByFundQuery,
  useCreateUpdateStartupMutation,
  useGetStartupQuery,
  useGetStartupMetaQuery,
  useRemoveStartupMutation,
} = startupApi;
