import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { UserAuthContextProvider } from "./context/userAuthContext";
import { configureBaseURLAxios } from "./utils/axios";
import { StartupApplicationGuard } from "./guards/StartupApplicationGuard";
import { store } from "./redux/store";

configureBaseURLAxios();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <UserAuthContextProvider>
        <StartupApplicationGuard>
          <App />
        </StartupApplicationGuard>
      </UserAuthContextProvider>
    </BrowserRouter>
  </Provider>
);
