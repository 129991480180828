import { Home as HomeIcon, Menu, ViewList } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  SwipeableDrawer,
} from "@mui/material";
import { useCallback, useContext, useMemo, useState } from "react";
import { userAuthContext } from "../../context/userAuthContext";
import { pathLinks } from "../../routes/paths";
import { NavConfigItem } from "./types";
import { NavList } from "./NavList";
import { LoginButton } from "../../components/LoginButton";
import { UserRole } from "../../@types/api";
import { useLocation } from "react-router-dom";

export const NavSection = () => {
  const { user, logOut } = useContext(userAuthContext);
  const [open, setOpen] = useState(() => !user);
  const { pathname } = useLocation();

  const navConfig: NavConfigItem[] = useMemo(
    () => [
      {
        icon: <HomeIcon />,
        title: "Home",
        path: pathLinks.home,
        hidden: user?.role === UserRole.Startup,
      },
      {
        icon: <ViewList />,
        title: "Startup list",
        path: pathLinks.startupList,
        hidden: user?.role === UserRole.Startup,
      },
    ],
    [user]
  );

  const handleToggle = () => setOpen((open) => !open);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = () => setOpen(false);

  return (
    <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
      <IconButton onClick={handleToggle}>
        <Menu />
      </IconButton>
      <SwipeableDrawer
        open={open}
        anchor="left"
        onOpen={handleOpen}
        onClose={handleClose}
        disableSwipeToOpen={false}
        swipeAreaWidth={20}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: 280,
            height: "100%",
          }}
          role="presentation"
        >
          {user && (
            <>
              <Box sx={{ p: 2 }}>{user.email}</Box>
              <Divider />
            </>
          )}

          {user && <NavList config={navConfig} onClose={handleClose} />}
          <Divider />
          <Box sx={{ p: 2 }}>
            {!!user && (
              <Button variant="contained" fullWidth onClick={logOut}>
                Logout
              </Button>
            )}
            {!user && (
              <Stack direction="column" gap={2}>
                {pathname.includes("link-form") ? (
                  <LoginButton as={UserRole.Startup} />
                ) : (
                  <LoginButton as={UserRole.Fund} />
                )}
              </Stack>
            )}
          </Box>
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};
