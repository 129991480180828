import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

interface CollapseButtonProps {
  open: boolean;
  title: string | JSX.Element;
  onToggle: VoidFunction;
}

export const CollapseButton = ({
  open,
  title,
  onToggle,
}: CollapseButtonProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
        gap: "20px",
        alignItems: "center",
        justifyContent: "space-between",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "rgb(240,240,240)",
        },
      }}
      onClick={onToggle}
    >
      <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
        {typeof title === "string" ? (
          <Typography variant="h6">{title}</Typography>
        ) : (
          title
        )}
        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </Box>
    </Box>
  );
};
