import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../firebase";

interface FileToUpload {
  id: string;
  file: File;
}

export const uploadFiles = async (
  filesToUpload: FileToUpload[],
  path: string
) => {
  const snapshotPromises = filesToUpload.map((fileToUpload) => {
    const storageRef = ref(storage, `${path}/${fileToUpload.id}`);
    return uploadBytes(storageRef, fileToUpload.file);
  });

  try {
    const result = await Promise.all(snapshotPromises);
    return result;
  } catch (error) {
    throw error;
  }
};

// 'file' comes from the Blob or File API
