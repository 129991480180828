import {
  Box,
  Button,
  Stack,
  TextField,
  TextareaAutosize,
  Tooltip,
  Typography,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useGetStartup } from "./useGetStartup";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { OrganizationSchema } from "../../@types/startup";
import { StartupOrganization, Member } from "../../@types/api";
import { EditMemberValidationSchema } from "./Members/schema";
import {
  EditMember,
  CreateEditMemberDialog,
} from "./Members/CreateEditMemberDialog";
import { useEffect, useState } from "react";
import { MembersList } from "./Members/MembersList";
import { CurrencyInput } from "./components/CurrencyInput";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { StartupNameAutocomplete } from "../../components/Startup/StartupNameAutocomplete";
import { useGetStartupOptions } from "../../components/Startup/StartupNameAutocomplete/useGetStartupOptions";
import { createUpdateStartup } from "../../api/startup";
import { UploadPitchDeck } from "../../components/Startup/UploadPitchDeck";
import { StartupDetailsForm } from "./types";
import { usePitchDeck } from "../../components/Startup/UploadPitchDeck/usePitchDeck";
import { CollapseButton } from "../../uiKit/CollapseButton";
import {
  OtherReferenceValidationSchema,
  OtherReferencesList,
} from "../../components/Startup/OtherReferencesList";
import { v4 } from "uuid";
import { VisitCardsList } from "../../components/Startup/VisitCardsList";
import { DialogQrScanner } from "../../components/DialogQrScanner";
import { DialogAdditionalInformation } from "../../components/Startup/DialogAdditionalInformation";
import { uploadFiles } from "../../firebase-services/uploadFiles";
import { storagePaths } from "../../firebase-config/storage";

const StartupDetailsValidationSchema = Yup.object().shape({
  organization: OrganizationSchema.required(),
  web: Yup.string(),
  email: Yup.string().email("Incorrect email"),
  founderFirstName: Yup.string(),
  founderLastName: Yup.string(),
  linkedIn: Yup.string(),
  industry: Yup.string(),
  market: Yup.string(),
  teamSize: Yup.number(),
  members: Yup.array().of(EditMemberValidationSchema).required(),
  marketSize: Yup.number(),
  serviceDifferentiation: Yup.string(),
  businessModel: Yup.string(),
  notes: Yup.string().trim(),
  otherReferences: Yup.array().of(OtherReferenceValidationSchema).required(),
  visitCards: Yup.mixed(),
  traction: Yup.object()
    .shape({
      usersNumber: Yup.number().required(),
      newUsersNumberPerMonth: Yup.number().required(),
    })
    .required(),
  financialMetrics: Yup.object().shape({
    mrr: Yup.object().shape({
      value: Yup.number().required("Monthly recurring rate is required"),
      currency: Yup.string().oneOf(["EUR", "USD"]).required(),
    }),
    monthlyBurnRate: Yup.object().shape({
      value: Yup.number().required("Monthly burn rate is required"),
      currency: Yup.string().oneOf(["EUR", "USD"]).required(),
    }),
  }),
});

const initialValues: StartupDetailsForm = {
  organization: {
    title: "",
    uuid: "",
    description: "",
  },
  web: "",
  email: "",
  founderFirstName: "",
  founderLastName: "",
  linkedIn: "",
  industry: "",
  market: "",
  teamSize: 1,
  members: [],
  marketSize: 1,
  serviceDifferentiation: "",
  businessModel: "",
  notes: "",
  otherReferences: [],
  visitCards: [],
  traction: { usersNumber: 0, newUsersNumberPerMonth: 0 },
  financialMetrics: {
    mrr: { currency: "EUR", value: 0 },
    monthlyBurnRate: { currency: "EUR", value: 0 },
  },
};

export const StartupDetails = () => {
  const { id } = useParams();
  const {
    options,
    loading: optionsLoading,
    handleInputChangeStartupName,
    reset: resetOptions,
  } = useGetStartupOptions();

  const { startup } = useGetStartup(id);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    getValues,
    resetField,
    formState: { errors },
  } = useForm<StartupDetailsForm>({
    resolver: yupResolver(StartupDetailsValidationSchema),
    defaultValues: initialValues,
  });
  const { preload, pitchDeck, pitchDeckRef } = usePitchDeck();

  const [loading, setLoading] = useState(false);
  const [editMember, setEditMember] = useState<Member | null>(null);
  const [openMemberDialog, setOpenMemberDialog] = useState(false);
  const [openMembersList, setOpenMembersList] = useState(false);
  const [openQrScanner, setOpenQrScanner] = useState(false);
  const [openAdditionalInformation, setOpenAdditionalInformation] =
    useState(false);

  const handleOpenMemberDialog = () => setOpenMemberDialog(true);
  const handleCloseMemberDialog = () => setOpenMemberDialog(false);
  const handleToggleMembersList = () => setOpenMembersList(!openMembersList);
  const handleOpenQrScanner = () => setOpenQrScanner(true);
  const handleCloseQrScanner = () => setOpenQrScanner(false);
  const handleOpenAdditionalInformation = () =>
    setOpenAdditionalInformation(true);
  const handleCloseAdditionalInformation = () =>
    setOpenAdditionalInformation(false);

  const otherReferences = watch("otherReferences");
  const visitCards = watch("visitCards");

  const handleSaveMember = (member: EditMember) => {
    const members = getValues("members");
    setValue("members", [...members, member]);
    handleCloseMemberDialog();
  };

  const handleEditMember = (member: EditMember) => {
    const members = getValues("members");
    const updatedMembers = members.map((item) =>
      item.id === member.id ? member : item
    );
    setValue("members", updatedMembers);
    handleCloseMemberDialog();
  };

  const handleRemoveMember = (id: string) => {
    const members = getValues("members");
    const filteredMembers = members.filter((member) => member.id !== id);
    setValue("members", filteredMembers);
  };

  const handleClickMember = (member: Member) => {
    handleOpenMemberDialog();
    setEditMember(member);
  };

  // const handleSubmitQrScanner = (result: string) => {
  //   const id = v4();
  //   setValue("otherReferences", [...otherReferences, { id, title: result }]);
  // };

  const handleSubmitQrScanner = (result: string) => {
    const id = v4();
    setValue("otherReferences", [...otherReferences, { id, title: result }]);
  };

  const handleDeleteOtherReference = (id: string) => {
    setValue(
      "otherReferences",
      otherReferences.filter((reference) => reference.id !== id)
    );
  };

  const handleTakePicture = (picture: File) => {
    const id = v4();
    setValue("visitCards", [...visitCards, { id, image: picture }]);
  };

  const handleDeleteVisitCard = (id: string) => {
    setValue(
      "visitCards",
      visitCards.filter((visitCard) => visitCard.id !== id)
    );
  };

  const handleClearAdditionalInformation = () => {
    setValue("visitCards", []);
    setValue("otherReferences", []);
  };

  const onSubmit = async (values: StartupDetailsForm) => {
    if (!startup?.id) return;

    const otherReferences = values.otherReferences.map(
      (reference) => reference.title
    );
    const visitCardsToUpload = values.visitCards.filter(
      (visitCard) => typeof visitCard.image !== "string"
    ) as { id: string; image: File }[];
    const imagesToUpload = visitCardsToUpload.map((visitCard) => ({
      id: visitCard.id,
      file: visitCard.image,
    }));

    const visitCardIds = values.visitCards.map((visitCard) => visitCard.id);

    try {
      setLoading(true);
      if (visitCardsToUpload.length) {
        await uploadFiles(imagesToUpload, storagePaths.visitCards);
      }

      console.log(values.traction.usersNumber);
      const { message } = await createUpdateStartup({
        id: startup.id,
        data: { ...values, otherReferences, visitCards: visitCardIds },
      });
      toast.success(message);
      setLoading(false);
    } catch (error) {
      const err = error as any;
      toast.error(err?.response?.data?.message || "Server error");
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    !openMemberDialog && setEditMember(null);
  }, [openMemberDialog]);

  useEffect(() => {
    startup?.pitchDeck && id && preload(startup.pitchDeck, id);
  }, [startup?.pitchDeck, id, preload]);

  useEffect(() => {
    const otherReferences = startup?.otherReferences || [];
    const otherReferencesWithId = otherReferences.map((reference) => ({
      id: v4(),
      title: reference,
    }));

    reset({
      organization: startup?.organization || {
        title: "",
        uuid: "",
        description: "",
      },
      web: startup?.web || "",
      email: startup?.email || "",
      founderFirstName: startup?.founderFirstName || "",
      founderLastName: startup?.founderLastName || "",
      linkedIn: startup?.linkedIn || "",
      industry: startup?.industry || "",
      market: startup?.market || "",
      teamSize: startup?.teamSize || 1,
      members: startup?.members || [],
      marketSize: startup?.marketSize || 1,
      serviceDifferentiation: startup?.serviceDifferentiation || "",
      businessModel: startup?.businessModel || "",
      notes: startup?.notes || "",
      otherReferences: otherReferencesWithId,
      visitCards: startup?.visitCards || [],
      traction: {
        usersNumber: startup?.traction?.usersNumber || 0,
        newUsersNumberPerMonth: startup?.traction?.newUsersNumberPerMonth || 0,
      },
      financialMetrics: {
        mrr: {
          currency: startup?.financialMetrics?.mrr?.currency || "EUR",
          value: startup?.financialMetrics?.mrr?.value || 0,
        },
        monthlyBurnRate: {
          currency:
            startup?.financialMetrics?.monthlyBurnRate?.currency || "EUR",
          value: startup?.financialMetrics?.monthlyBurnRate?.value || 0,
        },
      },
    });
  }, [startup, reset]);

  const resetStartupName = () => resetField("organization");

  return (
    <Box
      sx={{
        mt: {
          xs: "50px",
          sm: 0,
        },
      }}
    >
      <Typography sx={{ textAlign: "center" }} variant="h5">
        Startup Details
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "32px",
          padding: "24px",
          gap: "24px",
        }}
      >
        <Stack direction={{ xs: "column", sm: "row" }} gap={2}>
          <Button
            sx={{ flex: "1" }}
            fullWidth
            variant="outlined"
            onClick={handleOpenAdditionalInformation}
          >
            Add Additional Information
          </Button>
          {!!otherReferences.length || !!visitCards.length ? (
            <Button
              sx={{ flex: "1" }}
              variant="contained"
              color="error"
              onClick={handleClearAdditionalInformation}
            >
              Clear Additional Information
            </Button>
          ) : null}
        </Stack>
        {!!otherReferences.length && (
          <OtherReferencesList
            references={otherReferences}
            onDelete={handleDeleteOtherReference}
          />
        )}
        {!!visitCards.length && (
          <VisitCardsList
            visitCards={visitCards}
            onDelete={handleDeleteVisitCard}
          />
        )}
        <Controller
          name="organization"
          control={control}
          render={({ field }) => (
            <StartupNameAutocomplete
              options={options}
              loading={optionsLoading}
              error={!!errors.organization?.title}
              helperText={errors.organization?.title?.message}
              required
              value={field.value}
              onInputChange={handleInputChangeStartupName}
              onBlur={resetOptions}
              onReset={resetStartupName}
              onChange={(value: StartupOrganization) => field.onChange(value)}
            />
          )}
          defaultValue={undefined}
        />
        <Controller
          name="web"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Web"
              error={!!errors.web}
              helperText={errors.web?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Email"
              required
              error={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="founderFirstName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Founder first name"
              error={!!errors.founderFirstName}
              helperText={errors.founderFirstName?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="founderLastName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Founder last name"
              error={!!errors.founderLastName}
              helperText={errors.founderLastName?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="linkedIn"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="LinkedIn"
              error={!!errors.linkedIn}
              helperText={errors.linkedIn?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="industry"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Industry"
              error={!!errors.industry}
              helperText={errors.industry?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="market"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Market"
              error={!!errors.market}
              helperText={errors.market?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="teamSize"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              type="number"
              label="Team size"
              error={!!errors.teamSize}
              helperText={errors.teamSize?.message}
              inputProps={{ min: 1 }}
            />
          )}
          defaultValue={1}
        />
        <Controller
          name="marketSize"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              type="number"
              label="Market size"
              error={!!errors.marketSize}
              helperText={errors.marketSize?.message}
              inputProps={{ min: 1 }}
            />
          )}
          defaultValue={1}
        />
        <Controller
          name="serviceDifferentiation"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              multiline
              label="Product/Service differentiation"
              error={!!errors.serviceDifferentiation}
              helperText={errors.serviceDifferentiation?.message}
              InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: {
                  style: {
                    resize: "vertical",
                    minHeight: "80px",
                  },
                },
              }}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="businessModel"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              multiline
              label="Business model"
              error={!!errors.businessModel}
              helperText={errors.businessModel?.message}
              InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: {
                  style: {
                    resize: "vertical",
                    minHeight: "80px",
                  },
                },
              }}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              multiline
              label="Notes"
              error={!!errors.notes}
              helperText={errors.notes?.message}
              InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: {
                  style: {
                    resize: "vertical",
                    minHeight: "80px",
                  },
                },
              }}
            />
          )}
          defaultValue=""
        />
        {!!id && (
          <UploadPitchDeck
            id={id}
            value={pitchDeck}
            pitchDeckRef={pitchDeckRef}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            marginTop: 3,
          }}
        >
          <Typography variant="h6">Traction</Typography>
          <Controller
            name="traction.usersNumber"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                type="number"
                label="Number of users"
                error={!!errors.traction?.usersNumber}
                helperText={errors.traction?.usersNumber?.message}
                inputProps={{ min: 0 }}
              />
            )}
            defaultValue={0}
          />
          <Tooltip
            title="New users per month (last 3 months average)"
            placement="top"
          >
            <Box>
              <Controller
                name="traction.newUsersNumberPerMonth"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    {...field}
                    type="number"
                    label="New users per month (last 3 months average)"
                    error={!!errors.traction?.newUsersNumberPerMonth}
                    helperText={
                      errors.traction?.newUsersNumberPerMonth?.message
                    }
                    inputProps={{ min: 0 }}
                  />
                )}
                defaultValue={0}
              />
            </Box>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
            marginTop: 3,
          }}
        >
          <Typography variant="h6">Revenue and financial metrics</Typography>
          <CurrencyInput
            control={control}
            tooltipTitle="Monthly recurring revenue"
            textField={{
              name: "financialMetrics.mrr.value",
              error: !!errors.financialMetrics?.mrr?.value,
              helperText: errors.financialMetrics?.mrr?.value?.message,
              label: "Monthly recurring revenue",
            }}
            currency={{
              name: "financialMetrics.mrr.currency",
              error: !!errors.financialMetrics?.mrr?.currency,
              helperText: errors.financialMetrics?.mrr?.currency?.message,
            }}
          />
          <CurrencyInput
            control={control}
            textField={{
              name: "financialMetrics.monthlyBurnRate.value",
              error: !!errors.financialMetrics?.monthlyBurnRate?.value,
              helperText:
                errors.financialMetrics?.monthlyBurnRate?.value?.message,
              label: "Monthly burn rate",
            }}
            currency={{
              name: "financialMetrics.monthlyBurnRate.currency",
              error: !!errors.financialMetrics?.monthlyBurnRate?.currency,
              helperText:
                errors.financialMetrics?.monthlyBurnRate?.currency?.message,
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box>
            <Button
              onClick={handleOpenMemberDialog}
              size="small"
              variant="outlined"
            >
              Add member
            </Button>
          </Box>
          <>
            <CollapseButton
              open={openMembersList}
              onToggle={handleToggleMembersList}
              title="Team Members"
            />
            <MembersList
              open={openMembersList}
              members={watch("members")}
              onClick={handleClickMember}
              onRemove={handleRemoveMember}
            />
          </>
        </Box>
        <CreateEditMemberDialog
          member={editMember}
          open={openMemberDialog}
          onClose={handleCloseMemberDialog}
          onCreate={handleSaveMember}
          onEdit={handleEditMember}
        />
        <>
          <DialogAdditionalInformation
            open={openAdditionalInformation}
            onClose={handleCloseAdditionalInformation}
            onScanQr={handleOpenQrScanner}
            onTakePicture={handleTakePicture}
          />
          <DialogQrScanner
            open={openQrScanner}
            onClose={handleCloseQrScanner}
            onDecode={handleSubmitQrScanner}
          />
        </>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Submit
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};
