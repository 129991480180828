import { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { userAuthContext } from "../context/userAuthContext";
import { UserRole } from "../@types/api";

interface ProtectedRouteProps {
  role: UserRole;
  children: any;
}

export interface LocationState {
  pathToNavigate?: string | null;
}

export const ProtectedRoute = ({ role, children }: ProtectedRouteProps) => {
  const { user } = useContext(userAuthContext);
  const location = useLocation();

  if (user && user.role === role) return children;

  if (role === UserRole.Fund)
    return (
      <Navigate
        to="/login/fund"
        state={{ pathToNavigate: location.pathname }}
      />
    );

  return (
    <Navigate
      to="/login/startup"
      state={{ pathToNavigate: location.pathname }}
    />
  );
};
