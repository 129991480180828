import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditMemberValidationSchema } from "./schema";
import { memberAssignments, memberRoles } from "../../../@config/startup";
import * as Yup from "yup";
import { v4 } from "uuid";
import { useEffect } from "react";
import {
  StartupMemberAssignment,
  StartupMemberRole,
  Member,
} from "../../../@types/api";

interface CreateEditMemberDialogProps {
  member: Member | null;
  open: boolean;
  onClose: VoidFunction;
  onCreate: (member: EditMember) => void;
  onEdit: (member: EditMember) => void;
}

export interface EditMember
  extends Yup.InferType<typeof EditMemberValidationSchema> {}

export const CreateEditMemberDialog = ({
  open,
  member,
  onClose,
  onCreate,
  onEdit,
}: CreateEditMemberDialogProps) => {
  const initialValues: EditMember = {
    id: v4(),
    firstName: "",
    lastName: "",
    role: StartupMemberRole.TeamMember,
    shares: "",
    assignment: StartupMemberAssignment.FullTime,
  };

  const {
    control,
    watch,
    handleSubmit,
    resetField,
    reset,
    formState: { errors },
  } = useForm<EditMember>({
    resolver: yupResolver(EditMemberValidationSchema),
    defaultValues: initialValues,
  });

  useEffect(() => {
    reset({
      id: member?.id || v4(),
      firstName: member?.firstName || "",
      lastName: member?.lastName || "",
      role: member?.role || StartupMemberRole.TeamMember,
      shares: member?.shares || "",
      assignment: member?.assignment || StartupMemberAssignment.FullTime,
    });
  }, [member, reset, open]);

  const onSubmit = (values: EditMember) => {
    member ? onEdit(values) : onCreate(values);
    reset();
  };

  return (
    <Dialog
      PaperProps={{
        position: "relative",
      }}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}
    >
      <Close
        onClick={onClose}
        sx={{ position: "absolute", right: 20, top: 20, cursor: "pointer" }}
      />
      <DialogTitle>{member ? "Edit member" : "Create member"}</DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <Controller
          name="firstName"
          control={control}
          render={({ field }) => (
            <TextField
              sx={{ mt: 1 }}
              fullWidth
              {...field}
              label="First name"
              required
              error={!!errors.firstName}
              helperText={errors.firstName?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="lastName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Last name"
              required
              error={!!errors.lastName}
              helperText={errors.lastName?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => {
            const handleChange = (
              event: SelectChangeEvent<StartupMemberRole>
            ) => {
              if (event.target.value === "Founder") resetField("shares");

              field.onChange(event);
            };

            return (
              <FormControl>
                <InputLabel id="role">Role</InputLabel>
                <Select
                  labelId="role"
                  fullWidth
                  {...field}
                  onChange={handleChange}
                >
                  {memberRoles.map((role) => (
                    <MenuItem key={role} value={role}>
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
          defaultValue={StartupMemberRole.TeamMember}
        />
        {watch("role") === "Founder" && (
          <Controller
            name="shares"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Shares"
                error={!!errors.shares}
                helperText={errors.shares?.message}
              />
            )}
            defaultValue=""
          />
        )}
        <Controller
          name="assignment"
          control={control}
          render={({ field }) => (
            <FormControl>
              <InputLabel id="assignment">Assignment</InputLabel>
              <Select labelId="assignment" fullWidth {...field}>
                {memberAssignments.map((assignment) => (
                  <MenuItem key={assignment} value={assignment}>
                    {assignment}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          defaultValue={StartupMemberAssignment.FullTime}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(onSubmit)} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
