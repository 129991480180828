import { useMemo } from "react";
import { NavConfigItem } from "./types";
import { useLocation } from "react-router-dom";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  alpha,
} from "@mui/material";

interface NavItemProps {
  item: NavConfigItem;
  onClick: VoidFunction;
}

export const NavItem = ({ item, onClick }: NavItemProps) => {
  const { pathname } = useLocation();

  const active = useMemo(() => {
    return pathname === item.path;
  }, [item, pathname]);

  return (
    <ListItem disablePadding>
      <ListItemButton
        sx={{
          backgroundColor: active ? alpha("rgb(210,210,210)", 0.4) : "white",
        }}
        onClick={onClick}
      >
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.title} />
      </ListItemButton>
    </ListItem>
  );
};
