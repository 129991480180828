import { Navigate, useRoutes } from "react-router-dom";
import { LinkForm } from "../pages/LinkForm";
import { StartupDetails } from "../pages/StartupDetails";
import { MainLayout } from "../layouts/main";
import { CreateStartup } from "../pages/CreateStartup";
import { StartupList } from "../pages/StartupList/StartupList";
import { pathLinks } from "./paths";
import { Home } from "../pages/Home";
import { RoleRouteGuard } from "../guards/RoleRouteGuard";
import { UserRole } from "../@types/api";
import { Page404 } from "../pages/Page404";
import { LoginFund } from "../pages/LoginFund";
import { LoginStartup } from "../pages/LoginStartup";
import { ProtectedRoute } from "../guards/ProtectedRoute";

export const Router = () => {
  return useRoutes([
    {
      path: "/",
      element: <Navigate to={pathLinks.home} />,
    },
    {
      path: "/home",
      element: (
        <ProtectedRoute role={UserRole.Fund}>
          <RoleRouteGuard restrictedRoles={[UserRole.Startup]}>
            <MainLayout>
              <Home />
            </MainLayout>
          </RoleRouteGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "/startup/:id",
      element: (
        <ProtectedRoute role={UserRole.Fund}>
          <RoleRouteGuard restrictedRoles={[UserRole.Startup]}>
            <MainLayout>
              <CreateStartup />
            </MainLayout>
          </RoleRouteGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "/startup/list",
      element: (
        <ProtectedRoute role={UserRole.Fund}>
          <RoleRouteGuard restrictedRoles={[UserRole.Startup]}>
            <MainLayout>
              <StartupList />
            </MainLayout>
          </RoleRouteGuard>
        </ProtectedRoute>
      ),
    },
    {
      path: "startup-details/:id",
      element: (
        <ProtectedRoute role={UserRole.Fund}>
          <MainLayout>
            <StartupDetails />
          </MainLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "link-form/:id",
      element: (
        <ProtectedRoute role={UserRole.Startup}>
          <MainLayout>
            <LinkForm />
          </MainLayout>
        </ProtectedRoute>
      ),
    },
    {
      path: "/login",
      children: [
        {
          path: "fund",
          element: <LoginFund />,
        },
        {
          path: "startup",
          element: <LoginStartup />,
        },
      ],
    },
    {
      path: "404",
      element: <Page404 />,
    },
    {
      path: "*",
      element: <Navigate to="404" />,
    },
  ]);
};
