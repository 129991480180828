import { api } from ".";
import { CreateEventDto, EventEntity, UpdateEventDto } from "../../@types/api";

interface UpdateEventRequest {
  id: string;
  data: UpdateEventDto;
}

export const eventsApi = api.injectEndpoints({
  endpoints: (build) => ({
    createEvent: build.mutation<EventEntity, CreateEventDto>({
      query: (data) => ({
        url: "/events",
        method: "POST",
        data,
      }),
      invalidatesTags: ["EventsListByFund"],
    }),
    updateEvent: build.mutation<EventEntity, UpdateEventRequest>({
      query: ({ id, data }) => ({
        url: `/events/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["EventsListByFund"],
    }),
    getEventById: build.query<EventEntity, string>({
      query: (id) => ({
        url: `/events/${id}`,
        method: "GET",
      }),
    }),
    getEventsByFundId: build.query<EventEntity[], string>({
      query: (id) => ({
        url: `/events/fund/${id}`,
        method: "GET",
      }),
      providesTags: ["EventsListByFund"],
    }),
  }),
});

export const {
  useCreateEventMutation,
  useGetEventByIdQuery,
  useGetEventsByFundIdQuery,
} = eventsApi;
