import {
  Autocomplete,
  IconButton,
  Stack,
  SxProps,
  TextField,
} from "@mui/material";
import { useGetEventsByFundIdQuery } from "../../redux/api/events";
import { useContext, useEffect, useState } from "react";
import { userAuthContext } from "../../context/userAuthContext";
import { EventEntity } from "../../@types/api";
import { Add } from "@mui/icons-material";
import { DialogCreateEvent } from "../DialogCreateEvent";
import { useAppDispatch } from "../../redux/store";
import { clearDefaultEvent } from "../../redux/features/events";

interface AutocompleteEventProps {
  value: EventEntity | null | undefined;
  onChange: (value: EventEntity | null) => void;
  onReset: VoidFunction;
  sx?: SxProps;
}

export const AutocompleteEvent = ({
  value,
  onChange,
  sx,
}: AutocompleteEventProps) => {
  const dispatch = useAppDispatch();
  const { user } = useContext(userAuthContext);

  const { data: events } = useGetEventsByFundIdQuery(user?.fund?.id || "", {
    skip: !user?.fund?.id,
  });

  const [openCreateEventDialog, setOpenCreateEventDialog] = useState(false);

  const handleOpenCreateEventDialog = () => setOpenCreateEventDialog(true);
  const handleCloseCreateEventDialog = () => setOpenCreateEventDialog(false);

  const handleChange = (value: EventEntity | null) => {
    onChange(value);
  };

  return (
    <>
      <Stack
        sx={{ alignItems: "center", width: "100%", ...sx }}
        direction="row"
        gap="10px"
      >
        <Autocomplete
          size="small"
          value={value}
          onChange={(_, value) => handleChange(value)}
          onReset={() => handleChange(null)}
          options={events || []}
          disablePortal
          fullWidth
          getOptionLabel={(option) => option.title}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Event"
              placeholder="Select Event..."
            />
          )}
        />
        <IconButton onClick={handleOpenCreateEventDialog}>
          <Add />
        </IconButton>
      </Stack>
      <DialogCreateEvent
        open={openCreateEventDialog}
        onClose={handleCloseCreateEventDialog}
      />
    </>
  );
};
