import { Box, Button } from "@mui/material";
import { PdfThumbnail } from "./PdfThumbnail";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { uploadFiles } from "../../../firebase-services/uploadFiles";
import { storagePaths } from "../../../firebase-config/storage";

interface UploadPitchDeckProps {
  pitchDeckRef: React.RefObject<HTMLInputElement>;
  id: string;
  value?: File | null;
}

export const UploadPitchDeck = ({
  value,
  pitchDeckRef,
  id,
}: UploadPitchDeckProps) => {
  const [pitchDeck, setPitchDeck] = useState<File | null>(null);

  useEffect(() => {
    value && setPitchDeck(value);
  }, [value]);

  const handleChangePitchDeck = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!e.target.files?.length) return toast.error("Incorrect file");

    const filesToUpload = [
      {
        id: `${id}.pdf`,
        file: e.target.files[0],
      },
    ];

    try {
      await uploadFiles(filesToUpload, storagePaths.pitchDeck);
      setPitchDeck(e.target.files[0]);
    } catch (error) {
      toast.error("Upload file error");
    }
  };

  // const handleRemovePitchDeck = () => {
  //   setPitchDeck(null);
  //   if (pitchDeckRef.current?.files) {
  //     pitchDeckRef.current.value = "";
  //     pitchDeckRef.current.type = "text";
  //     pitchDeckRef.current.type = "file";
  //   }
  // };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "7px" }}>
      <Box>
        <Button variant="outlined" component="label">
          Upload pitch deck{" "}
          <input
            ref={pitchDeckRef}
            hidden
            name="file"
            type="file"
            accept=".pdf"
            multiple={false}
            onChange={handleChangePitchDeck}
          />
        </Button>
      </Box>
      {!!pitchDeck && (
        <PdfThumbnail
          pdf={pitchDeck}
          // onRemove={handleRemovePitchDeck}
        />
      )}
    </Box>
  );
};
