import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { signOut, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebase";
import jwt_decode from "jwt-decode";
// import { toast } from "react-toastify";
import {
  clearAuthorizationHeader,
  configureAuthorizationHeader,
} from "../utils/axios";
import { authenticate as authenticateUser } from "../api/auth";
import { AppUser } from "../@types/users";
import { toast } from "react-toastify";
import { UserResponse, UserRole } from "../@types/api";
import { LS_TOKEN_KEY } from "../localStorage/keys";

interface UserAuthContext {
  user: AppUser | null;
  authLoading: boolean;
  googleSignInWithPopup: (as: UserRole) => Promise<AppUser | undefined>;
  logOut: any;
  setUser: Dispatch<SetStateAction<AppUser | null>>;
}

export const userAuthContext = createContext<UserAuthContext>(
  {} as UserAuthContext
);

interface UserAuthContextProviderProps {
  children: React.ReactNode;
}

// const setupExpirationTimer = (exp: number, logOut: () => void) => {
//   const expirationTime = exp * 1000 - Date.now() - 60 * 1000 * 10;
//   const unsignedExpirationTime = expirationTime > 0 ? expirationTime : 0;
//   const notificationExpirationTime = expirationTime - 60 * 1000;
//   const unsignedNotificationExpirationTime =
//     notificationExpirationTime > 0 ? notificationExpirationTime : 0;

//   setTimeout(() => {
//     toast.warning("Session is about expiration. Please, relogin", {
//       autoClose: 10000,
//     });
//   }, unsignedNotificationExpirationTime);

//   setTimeout(() => {
//     logOut();
//     toast.info("Session was expired", { autoClose: 10000 });
//   }, unsignedExpirationTime);
// };

export const UserAuthContextProvider = ({
  children,
}: UserAuthContextProviderProps) => {
  const [user, setUser] = useState<AppUser | null>(null);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const authenticate = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setAuthLoading(false);
          return;
        }

        const { id, email, photo, role } = jwt_decode(token) as UserResponse;

        if (!email) {
          setAuthLoading(false);
          return;
        }

        const {
          data: { user },
        } = await authenticateUser({
          id,
          email,
          photo,
          role,
        });

        configureAuthorizationHeader(token);
        setUser({
          id,
          email,
          photoURL: photo || null,
          accessToken: token,
          fund: user.fund,
          role: user.role,
        });
        setAuthLoading(false);
        // setupExpirationTimer(exp, logOut);
      } catch (error) {
        console.log(error);
        setAuthLoading(false);
      }
    };
    authenticate();
  }, []);

  const logOut = () => {
    signOut(auth);
    localStorage.removeItem(LS_TOKEN_KEY);
    clearAuthorizationHeader();
    return setUser(null);
  };

  const googleSignInWithPopup = async (as: UserRole) => {
    try {
      setAuthLoading(true);
      const googleAuthProvider = new GoogleAuthProvider();
      const { user: authUser } = await signInWithPopup(
        auth,
        googleAuthProvider
      );

      const { uid, email, photoURL } = authUser;
      if (!email) {
        toast.error("Incorrect email");
        return;
      }

      const {
        data: { user, token },
      } = await authenticateUser({
        id: uid,
        email,
        photo: photoURL,
        role: as,
      });

      localStorage.setItem(LS_TOKEN_KEY, token);
      configureAuthorizationHeader(token);
      const appUser = {
        id: uid,
        email,
        photoURL,
        accessToken: token,
        fund: user.fund,
        role: user.role,
      };
      setUser(appUser);
      // setupExpirationTimer(exp, logOut);
      setAuthLoading(false);
      return appUser;
    } catch (error) {
      console.log(error);
      setAuthLoading(false);
    }
  };

  return (
    <userAuthContext.Provider
      value={{
        user,
        authLoading,
        googleSignInWithPopup,
        logOut,
        setUser,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
};

export function useUserAuth() {
  return useContext(userAuthContext);
}
