import {
  Collapse,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { CollapseButton } from "../../uiKit/CollapseButton";
import { useState } from "react";
import * as Yup from "yup";

export const VisitCardsValidationSchema = Yup.object().shape({
  id: Yup.string().required(),
  image: Yup.mixed().required(),
});

export type VisitCard = {
  id: string;
  image: File | string;
};

interface VisitCardsListProps {
  visitCards: VisitCard[];
  onDelete: (id: string) => void;
}

export const VisitCardsList = ({
  visitCards,
  onDelete,
}: VisitCardsListProps) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => setOpen(!open);

  return (
    <Stack direction="column" gap={3}>
      <CollapseButton
        open={open}
        onToggle={handleToggle}
        title={
          <Typography sx={{ fontWeight: "bold" }} variant="body1">
            Visit cards
          </Typography>
        }
      />
      <Collapse in={open}>
        <Stack direction="column" gap={1}>
          {visitCards.map((visitCard) => {
            const src =
              typeof visitCard.image === "string"
                ? visitCard.image
                : URL.createObjectURL(visitCard.image);

            return (
              <Stack
                sx={{ width: "100%", alignItems: "center" }}
                direction="row"
                key={visitCard.id}
              >
                <img
                  style={{ width: "100%" }}
                  src={src}
                  alt="Visit card"
                  height="auto"
                />
                <IconButton
                  sx={{ maxWidth: "40px", maxHeight: "40px" }}
                  onClick={() => onDelete(visitCard.id)}
                >
                  <Close sx={{ color: "gray" }} />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      </Collapse>
      {!!visitCards.length && open && <Divider />}
    </Stack>
  );
};
