import { Box, Button, Container, SvgIcon, Typography } from "@mui/material";
import GoogleIcon from "@mui/icons-material/Google";
import { useContext } from "react";
import { userAuthContext } from "../context/userAuthContext";
import { UserRole } from "../@types/api";
import { useLocation, useNavigate } from "react-router-dom";
import { LocationState } from "../guards/ProtectedRoute";

export const LoginStartup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { googleSignInWithPopup } = useContext(userAuthContext);

  const locationState = location.state as LocationState;
  const pathToNavigate = locationState.pathToNavigate;

  const handleLogin = () => {
    googleSignInWithPopup(UserRole.Startup);
    pathToNavigate && navigate(pathToNavigate);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: "#fff",
          padding: 3,
          borderRadius: 2,
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ mb: 2, fontWeight: "bold" }}
        >
          Startup Login
        </Typography>
        <Button
          variant="contained"
          startIcon={<SvgIcon component={GoogleIcon} />}
          onClick={handleLogin}
          sx={{
            textTransform: "none",
            backgroundColor: "#4285F4",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#357ae8",
            },
          }}
        >
          Sign in with Google
        </Button>
      </Box>
    </Container>
  );
};
