import {
  Box,
  Collapse,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { isValidUrl } from "../../utils/validation/url";
import { Close } from "@mui/icons-material";
import { CollapseButton } from "../../uiKit/CollapseButton";
import { useState } from "react";
import * as Yup from "yup";

export const OtherReferenceValidationSchema = Yup.object().shape({
  id: Yup.string().required(),
  title: Yup.string().required(),
});

export type OtherReference = Yup.InferType<
  typeof OtherReferenceValidationSchema
>;

interface OtherReferencesListProps {
  references: OtherReference[];
  onDelete: (id: string) => void;
}

export const OtherReferencesList = ({
  references,
  onDelete,
}: OtherReferencesListProps) => {
  const [open, setOpen] = useState(false);

  const handleToggleOtherReferences = () => setOpen(!open);
  const handleOpenLink = (link: string) => window.open(link);

  return (
    <Stack direction="column" gap={2}>
      <CollapseButton
        open={open}
        onToggle={handleToggleOtherReferences}
        title={
          <Typography sx={{ fontWeight: "bold" }} variant="body1">
            Other references
          </Typography>
        }
      />
      <Collapse in={open}>
        <Stack direction="column" gap={2}>
          {references.map((reference, index) => {
            if (!isValidUrl(reference.title))
              return (
                <Stack key={reference.id} direction="row" alignItems="center">
                  <TextField
                    sx={{ pointerEvents: "none" }}
                    fullWidth
                    multiline
                    label={`Reference ${index + 1}`}
                    value={reference.title}
                  />

                  <IconButton
                    sx={{ maxWidth: "40px", maxHeight: "40px" }}
                    onClick={() => onDelete(reference.id)}
                  >
                    <Close sx={{ color: "gray" }} />
                  </IconButton>
                </Stack>
              );

            return (
              <Stack
                sx={{ position: "relative" }}
                key={reference.id}
                direction="row"
                alignItems="center"
              >
                <Box
                  sx={{ width: "100%" }}
                  onClick={() => handleOpenLink(reference.title)}
                >
                  <TextField
                    sx={{ pointerEvents: "none" }}
                    fullWidth
                    label={`Reference ${index + 1} (Clickable)`}
                    value={reference.title}
                    onChange={undefined}
                  />
                </Box>
                <IconButton
                  sx={{
                    position: "absolute",
                    right: -40,
                    top: "50%",
                    transform: "translateY(-50%)",
                    maxWidth: "40px",
                    maxHeight: "40px",
                  }}
                  onClick={() => onDelete(reference.id)}
                >
                  <Close sx={{ color: "gray" }} />
                </IconButton>
              </Stack>
            );
          })}
        </Stack>
      </Collapse>
      {!!references.length && open && <Divider />}
    </Stack>
  );
};
