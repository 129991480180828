import { Button } from "@mui/material";
import { useContext } from "react";
import { userAuthContext } from "../context/userAuthContext";
import { UserRole } from "../@types/api";

interface LoginButtonProps {
  as: UserRole;
  variant?: "text" | "contained" | "outlined";
}

export const LoginButton = ({
  as,
  variant = "contained",
}: LoginButtonProps) => {
  const { googleSignInWithPopup } = useContext(userAuthContext);
  const title = as === UserRole.Startup ? "Login as Startup" : "Login as Fund";
  const handleClick = () => googleSignInWithPopup(as);

  return (
    <Button variant={variant} fullWidth onClick={handleClick}>
      {title}
    </Button>
  );
};
