import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";

interface CurrencyInputProps {
  control: Control<any>;
  tooltipTitle?: string;
  textField: {
    name: string;
    error: boolean;
    helperText: string | undefined;
    label: string;
  };
  currency: {
    name: string;
    error: boolean;
    helperText: string | undefined;
  };
}

const currencies = ["EUR", "USD"];

export const CurrencyInput = ({
  tooltipTitle,
  control,
  textField,
  currency,
}: CurrencyInputProps) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", gap: "10px" }}>
      <Tooltip title={tooltipTitle || ""} placement="top">
        <Box sx={{ width: "100%" }}>
          <Controller
            name={textField.name}
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                type="number"
                label={textField.label}
                error={!!textField.error}
                helperText={textField.helperText}
                inputProps={{ min: 0 }}
              />
            )}
            defaultValue={0}
          />
        </Box>
      </Tooltip>
      <Controller
        name={currency.name}
        control={control}
        render={({ field }) => (
          <FormControl sx={{ minWidth: "90px" }}>
            <InputLabel id={`currency-${currency.name}`}>Currency</InputLabel>
            <Select labelId={`currency-${currency.name}`} {...field}>
              {currencies.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Box>
  );
};
