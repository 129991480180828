import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { EventEntity } from "../../@types/api";
import { LS_EVENT_DEFAULT_KEY } from "../../localStorage/keys";

interface InitialState {
  default: EventEntity | null;
}

const initialState: InitialState = {
  default: null,
};

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setDefaultEvent(state, { payload }: PayloadAction<EventEntity | null>) {
      state.default = payload;
    },
    clearDefaultEvent(state) {
      state.default = null;
    },
    loadDefaultEventFromLS(state) {
      const event = localStorage.getItem(LS_EVENT_DEFAULT_KEY);
      if (event) {
        const event_obj = JSON.parse(event) as EventEntity;
        state.default = event_obj;
      } else {
        state.default = null;
      }
    },
  },
});

export const { setDefaultEvent, clearDefaultEvent, loadDefaultEventFromLS } =
  eventsSlice.actions;

export default eventsSlice.reducer;
