import { Box } from "@mui/material";
import { NavSection } from "./NavSection";

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1300px",
          background: "white",
        }}
      >
        <NavSection />
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "800px",
          minWidth: "280px",
          p: 3,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
