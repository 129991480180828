export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const getFileFromUrl = async (url: string, type: string) => {
  try {
    const response = await fetch(url);
    if (!response.ok) return null;

    const data = await response.blob();
    const metadata = {
      type,
    };
    return new File([data], url, metadata);
  } catch (error) {
    return null;
  }
};
