import { useEffect, useState } from "react";
import { StartupResponse, CrunchbaseOrganization } from "../../@types/api";
import { getStartup, getStartupMeta } from "../../api/startup";

export const useGetStartup = (id: string | undefined) => {
  const [startup, setStartup] = useState<null | StartupResponse>(null);
  const [meta, setMeta] = useState<null | CrunchbaseOrganization>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStartup = async () => {
      if (!id) return;

      try {
        const { data: startup } = await getStartup({ id });
        if (startup.organization.uuid) {
          const { data: meta } = await getStartupMeta({
            id: startup.organization.uuid,
          });
          setMeta(meta);
        }
        setStartup(startup);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchStartup();
  }, [id]);

  return { startup, meta, loading };
};
