import axios from "axios";
import { Response } from "../@types/response";
import {
  AutocompletesCrunchbaseDto,
  CrunchbaseAutocompletesResponse,
} from "../@types/api";

export const getCrunchbaseAutocompletes = async ({
  query,
  collection_ids = "organizations",
  limit = 5,
}: AutocompletesCrunchbaseDto) => {
  try {
    const response = await axios.get(
      `/crunchbase/autocompletes?query=${query}&collection_ids=${collection_ids}&limit=${limit}`
    );
    return response.data as Response<CrunchbaseAutocompletesResponse>;
  } catch (error) {
    throw error;
  }
};
