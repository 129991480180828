import { Box, Button } from "@mui/material";

export const Page404 = () => {
  const styles = {
    container: {
      textAlign: "center",
      padding: "50px",
      fontFamily: '"Helvetica Neue", Helvetica, Arial, sans-serif',
    },
    headline: {
      fontSize: "72px",
      fontWeight: "bold",
      color: "#232323",
    },
    text: {
      fontSize: "24px",
      color: "#666",
    },
    link: {
      marginTop: "20px",
      padding: "10px 15px",
      borderRadius: "5px",
      textDecoration: "none",
      fontWeight: "bold",
    },
  };

  return (
    <Box sx={styles.container}>
      <h1 style={styles.headline}>404</h1>
      <p style={styles.text}>Oops! The page you're looking for isn't here.</p>
      <p style={styles.text}>
        You might have the wrong address, or the page may have moved.
      </p>
      <Button component="a" variant="contained" href="/" style={styles.link}>
        Back to Home
      </Button>
    </Box>
  );
};
