import { Box, Typography } from "@mui/material";
// import { Cancel } from "@mui/icons-material";
import PdfFileIcon from "../../../assets/icons/PDF_file_icon.png";

interface PdfThumbnailProps {
  pdf: File;
  // onRemove: () => void;
}

export const PdfThumbnail = ({
  pdf,
}: // onRemove
PdfThumbnailProps) => {
  const handleClick = async () => {
    window.open(window.URL.createObjectURL(pdf));
  };

  // const handleRemove: React.MouseEventHandler<SVGSVGElement> = (e) => {
  //   e.stopPropagation();
  //   onRemove();
  // };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
      <Box
        sx={{
          position: "relative",
          width: "60px",
          height: "80px",
          padding: "7px",
          cursor: "pointer",
          transform: "scale(1)",
          "&:hover": {
            transition: ".3s",
            transform: "scale(1.05)",
          },
        }}
        onClick={handleClick}
      >
        <img
          style={{ width: "100%", height: "100%" }}
          src={PdfFileIcon}
          alt="pdf-icon"
        />
        {/* <Cancel
          onClick={handleRemove}
          sx={{
            backgroundColor: "white",
            borderRadius: "50%",
            position: "absolute",
            cursor: "pointer",
            top: 0,
            right: 0,
            color: "rgb(60,60,60);",
            transition: ".3s",
            transform: "scale(1)",
            "&:hover": {
              transform: "scale(1.1)",
              transition: ".3s",
            },
          }}
        /> */}
      </Box>
      <Typography variant="body2">{pdf.name}</Typography>
    </Box>
  );
};
