import { useEffect, useState } from "react";
import { EventEntity } from "../../@types/api";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { selectDefaultEvent } from "../../redux/selectors/events";
import {
  clearDefaultEvent,
  setDefaultEvent,
} from "../../redux/features/events";

interface UseAutocompleteEventConfig {
  isGloballyCached?: boolean;
}

export const useAutocompleteEvent = (
  config: UseAutocompleteEventConfig = { isGloballyCached: false }
) => {
  const dispatch = useAppDispatch();
  const defaultEvent = useAppSelector(selectDefaultEvent);

  const [event, setEvent] = useState<EventEntity | null>(null);

  const handleChangeEvent = (
    event: EventEntity | null,
    callback?: (value: EventEntity | null) => any
  ) => {
    config.isGloballyCached && dispatch(setDefaultEvent(event));
    setEvent(event);
    callback?.(event);
  };

  const handleResetEvent = () => {
    config.isGloballyCached && dispatch(clearDefaultEvent());
    setEvent(null);
  };

  useEffect(() => {
    defaultEvent && setEvent(defaultEvent);
  }, [defaultEvent]);

  return { event, setEvent, handleChangeEvent, handleResetEvent };
};
