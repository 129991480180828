import {
  Button,
  Card,
  Stack,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { pathLinks } from "../routes/paths";
import { v4 } from "uuid";
import { AutocompleteEvent } from "../components/Events/AutocompleteEvent";
import { useAutocompleteEvent } from "../components/Events/useAutocompleteEvent";

export const Home = () => {
  const navigate = useNavigate();
  const { event, handleChangeEvent, handleResetEvent } = useAutocompleteEvent({
    isGloballyCached: true,
  });
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const handleNavigateStartupList = () => navigate(pathLinks.startupList);
  const handleNavigateCreateStartup = () => {
    const id = v4();
    navigate(pathLinks.startup(id));
  };

  return (
    <Stack direction="column" gap={3}>
      <HomeCard>
        <Typography
          sx={{ textAlign: "center", mb: 2 }}
          color="InfoText"
          variant={mobile ? "h4" : "h2"}
        >
          Welcome to <br />
          <b style={{ color: "darkorange" }}>Boosterchance</b>
        </Typography>
        <Typography
          sx={{ textAlign: "center", mb: 3 }}
          color="CaptionText"
          variant="body1"
        >
          Want to create new startup? Click button below
        </Typography>
        <Button
          sx={{ mb: 3 }}
          variant="contained"
          size="large"
          startIcon={<Add />}
          onClick={handleNavigateCreateStartup}
        >
          Create New Startup
        </Button>
      </HomeCard>
      <Stack direction={{ sm: "row", xs: "column" }} gap={3}>
        <HomeCard>
          <Typography
            sx={{ textAlign: "center", mb: 2 }}
            color="InfoText"
            variant="h4"
          >
            Choose an Event
          </Typography>
          <Typography
            sx={{ textAlign: "center", mb: 3 }}
            color="CaptionText"
            variant="body1"
          >
            <b>Create new or choose existing Event</b> for better understanding
            where you've found startups
          </Typography>
          <AutocompleteEvent
            value={event}
            onChange={handleChangeEvent}
            onReset={handleResetEvent}
          />
        </HomeCard>
        <HomeCard>
          <Typography
            sx={{ textAlign: "center", mb: 2 }}
            color="InfoText"
            variant="h4"
          >
            Startup List
          </Typography>
          <Typography
            sx={{ textAlign: "center", mb: 3 }}
            color="CaptionText"
            variant="body1"
          >
            Here you can navigate and observe all startups which your fund has
            added before
          </Typography>
          <Button
            sx={{ mb: 3 }}
            variant="outlined"
            onClick={handleNavigateStartupList}
          >
            Startup List
          </Button>
        </HomeCard>
      </Stack>
    </Stack>
  );
};

interface HomeCardProps {
  children?: any;
  sx?: SxProps;
}

const HomeCard = ({ children, sx }: HomeCardProps) => {
  return (
    <Card
      sx={{
        background: "#FDFFFC",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: "40px 24px 24px",
        width: "100%",
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};
