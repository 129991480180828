import { Theme } from "@mui/material";

export const Typography = (theme: Theme) => {
  return {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: '"Public Sans", sans-serif',
        },
      },
    },
  };
};
