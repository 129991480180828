// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBDLcq8enzx6tg8ZIBKUcz_68Q88VP3gb0",
  authDomain: "boosterchance.firebaseapp.com",
  projectId: "boosterchance",
  storageBucket: "boosterchance.appspot.com",
  messagingSenderId: "883369355026",
  appId: "1:883369355026:web:d41238ab15defed157b5cb",
  measurementId: "G-QQLH4VS3JZ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export default app;
