import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMatch } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { getStartup, createUpdateStartup } from "../../api/startup";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { StartupOrganization } from "../../@types/api";
import { StartupNameAutocomplete } from "../../components/Startup/StartupNameAutocomplete";
import { useGetStartupOptions } from "../../components/Startup/StartupNameAutocomplete/useGetStartupOptions";
import { uploadFiles } from "../../firebase-services/uploadFiles";
import { UploadPitchDeck } from "../../components/Startup/UploadPitchDeck";
import { usePitchDeck } from "../../components/Startup/UploadPitchDeck/usePitchDeck";
import { storagePaths } from "../../firebase-config/storage";

const LinkFormValidationSchema = Yup.object().shape({
  organization: Yup.object().shape({
    uuid: Yup.string(),
    title: Yup.string().required("Startup name is required"),
    description: Yup.string(),
  }),
  web: Yup.string().trim().required("Web is required"),
  email: Yup.string().trim().email().required("Startun email is required"),
  founderFirstName: Yup.string()
    .trim()
    .required("Founder first name is required"),
  founderLastName: Yup.string()
    .trim()
    .required("Founder last name is required"),
  accept: Yup.boolean().oneOf([true], "Should be checked").required(),
});

interface LinkFormValues {
  organization: StartupOrganization;
  web: string;
  email: string;
  founderFirstName: string;
  founderLastName: string;
  accept: boolean;
}

const initialValues: LinkFormValues = {
  organization: {
    uuid: "",
    title: "",
    description: "",
  },
  web: "",
  email: "",
  founderFirstName: "",
  founderLastName: "",
  accept: false,
};

export const LinkForm = () => {
  const match = useMatch("/link-form/:id");
  const {
    control,
    handleSubmit,
    reset,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(LinkFormValidationSchema),
    defaultValues: initialValues,
  });
  const { preload, pitchDeck, pitchDeckRef } = usePitchDeck();

  const [loading, setLoading] = useState(false);

  const {
    options,
    reset: resetOptions,
    request,
    loading: loadingOptions,
    handleInputChangeStartupName,
  } = useGetStartupOptions();

  const id = match?.params.id;

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;

      try {
        const { data } = await getStartup({ id });
        const defaultValues: LinkFormValues = {
          organization: data.organization || {
            uuid: "",
            title: "",
            description: "",
          },
          web: data.web || "",
          email: data.email || "",
          founderFirstName: data.founderFirstName || "",
          founderLastName: data.founderLastName || "",
          accept: false,
        };

        if (data.pitchDeck) {
          await preload(data.pitchDeck, id);
        }

        await request(defaultValues.organization.title);

        reset(defaultValues);
      } catch (error: any) {
        toast.error(error?.response?.data?.message || "Server Error");
        console.log(error);
      }
    };
    fetchData();
  }, [id, reset, request, preload]);

  const onSubmit = async (values: LinkFormValues) => {
    if (!id) return toast.error("Incorrect Link");
    setLoading(true);
    try {
      if (pitchDeck) {
        const filesToUpload = [
          {
            id: `${id}.pdf`,
            file: pitchDeck,
          },
        ];

        await uploadFiles(filesToUpload, storagePaths.pitchDeck);
      }
      const { message } = await createUpdateStartup({
        id,
        data: values,
      });
      toast.success(message);
      setLoading(false);
    } catch (error: any) {
      toast.error(error?.response?.data?.message || "Server Error");
      setLoading(false);
      console.log(error);
    }
  };

  const resetStartupName = () => resetField("organization");

  return (
    <Box
      sx={{
        display: "block",
        flexWrap: "wrap",
        margin: "auto",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "500px",
        width: "100%",
        minWidth: "280px",
      }}
    >
      <form style={{ width: "100%" }} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
          <Typography sx={{ textAlign: "center" }} variant="h5">
            Link Form
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            gap: 3,
            mt: 4,
            p: 3,
          }}
        >
          <Controller
            name="organization"
            control={control}
            render={({ field }) => (
              <StartupNameAutocomplete
                required
                options={options}
                value={field.value}
                loading={loadingOptions}
                onChange={field.onChange}
                onBlur={resetOptions}
                onReset={resetStartupName}
                error={!!errors.organization?.title}
                onInputChange={handleInputChangeStartupName}
              />
            )}
            defaultValue={undefined}
          />
          <Controller
            name="web"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Web"
                error={!!errors.web}
                helperText={errors.web?.message}
              />
            )}
            defaultValue=""
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Email"
                error={!!errors.email}
                helperText={errors.email?.message}
                required
              />
            )}
            defaultValue=""
          />
          <Controller
            name="founderFirstName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Founder first name"
                error={!!errors.founderFirstName}
                helperText={errors.founderFirstName?.message}
              />
            )}
            defaultValue=""
          />
          <Controller
            name="founderLastName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Founder last name"
                error={!!errors.founderLastName}
                helperText={errors.founderLastName?.message}
              />
            )}
            defaultValue=""
          />
          {!!id && (
            <UploadPitchDeck
              value={pitchDeck}
              id={id}
              pitchDeckRef={pitchDeckRef}
            />
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Controller
              name="accept"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} checked={field.value} />}
                  label="I agree with personal data processing according to GDPR regulations"
                />
              )}
              defaultValue={false}
            />
            {!!errors.accept && (
              <Typography color="red" variant="caption">
                {errors.accept.message}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton loading={loading} type="submit" variant="contained">
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
