import * as Yup from "yup";
import {
  Member,
  StartupMemberAssignment,
  StartupMemberRole,
} from "../../../@types/api";

export const EditMemberValidationSchema: Yup.ObjectSchema<Member> =
  Yup.object().shape({
    id: Yup.string().uuid().required(),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    shares: Yup.string(),
    role: Yup.mixed<StartupMemberRole>().required(),
    assignment: Yup.mixed<StartupMemberAssignment>().required(),
  });
