import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Tooltip,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { StartupResponse } from "../../@types/api";
import { pathLinks } from "../../routes/paths";
import { useRemoveStartupMutation } from "../../redux/api/startup";
import { toast } from "react-toastify";

interface StartupItemProps {
  startup: StartupResponse;
}

export const StartupItem = ({ startup }: StartupItemProps) => {
  const [removeStartup] = useRemoveStartupMutation();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = () => navigate(pathLinks.startupDetails(startup.id));
  const handleClickRemoveItem = async () => {
    try {
      await removeStartup(startup.id);
      handleCloseMenu();
    } catch (error) {
      toast.error("Server Error");
    }
  };

  const date = new Date(startup.created);

  return (
    <Box sx={{ display: "flex" }}>
      <Box
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "column",
          minWidth: 0,
          flex: 1,
          height: "72px",
          cursor: "pointer",
          padding: "10px 15px",
          "&:hover": { backgroundColor: "rgb(235, 235, 235);" },
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            sx={{
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            variant="h6"
          >
            {startup.organization.title}
          </Typography>
          <Typography sx={{ minWidth: "105px" }} variant="caption" color="gray">
            {date.toLocaleString("en-GB", {
              dateStyle: "short",
              timeStyle: "short",
            })}
          </Typography>
        </Box>
        {!!startup.organization.description && (
          <Tooltip title={startup.organization.description} placement="top">
            <Typography
              sx={{
                display: "block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
              variant="caption"
            >
              {startup.organization.description}
            </Typography>
          </Tooltip>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton onClick={handleOpenMenu}>
          <MoreVert />
        </IconButton>
        <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={handleCloseMenu}>
          <MenuItem onClick={handleClickRemoveItem} sx={{ color: "red" }}>
            Remove
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};
