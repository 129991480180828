import axios from "axios";
import { Response } from "../@types/response";
import { AuthResponse, CreateUserDto } from "../@types/api";

export const authenticate = async (data: CreateUserDto) => {
  try {
    const response = await axios.post("/auth", data);
    return response.data as Response<AuthResponse>;
  } catch (error) {
    throw error;
  }
};
