import axios from "axios";

export const configureBaseURLAxios = () => {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL_API;
};

export const configureAuthorizationHeader = (token: string) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
};

export const clearAuthorizationHeader = () => {
  axios.defaults.headers.common["Authorization"] = "";
};
