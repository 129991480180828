import { List } from "@mui/material";
import { NavConfigItem } from "./types";
import { useNavigate } from "react-router-dom";
import { NavItem } from "./NavItem";

interface NavListProps {
  config: NavConfigItem[];
  onClose: VoidFunction;
}

export const NavList = ({ config, onClose }: NavListProps) => {
  const navigate = useNavigate();

  const handleClickItem = (path: string) => {
    navigate(path);
    onClose();
  };

  return (
    <List disablePadding sx={{ flex: 1 }}>
      {config.map((item) => {
        if (item.hidden) return null;

        return (
          <NavItem
            key={item.title}
            item={item}
            onClick={() => handleClickItem(item.path)}
          />
        );
      })}
    </List>
  );
};
