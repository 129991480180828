import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { StartupOrganization } from "../../../@types/api";
import { useEffect, useState } from "react";

interface StartupNameAutocompleteProps {
  options: StartupOrganization[];
  value: StartupOrganization | null;
  loading: boolean;
  error: boolean;
  helperText?: string;
  disabled?: boolean;
  required?: boolean;
  onInputChange: (value: string) => void;
  onChange: (...event: any[]) => void;
  onBlur: VoidFunction;
  onReset: VoidFunction;
}

export const StartupNameAutocomplete = ({
  options,
  value,
  loading,
  error,
  helperText,
  disabled = false,
  required = false,
  onInputChange,
  onChange,
  onBlur,
  onReset,
}: StartupNameAutocompleteProps) => {
  const [inputValue, setInputValue] = useState("");
  const actualOptions = [
    { uuid: "", title: inputValue, description: "" },
    ...options,
  ];

  useEffect(() => {
    !value && setInputValue("");
  }, [value]);

  const handleChange = (_: any, value: any) => {
    if (!value) {
      onReset();
      setInputValue("");
      return;
    }
    onChange(value);
  };

  return (
    <Autocomplete
      autoSelect={false}
      disabled={disabled}
      options={actualOptions}
      value={value}
      filterOptions={(options) =>
        options.filter(() => {
          if (!inputValue) return false;
          return true;
        })
      }
      onInputChange={(_, value) => {
        onInputChange(value);
        setInputValue(value);
      }}
      disablePortal
      onChange={handleChange}
      isOptionEqualToValue={(option, value) =>
        option.uuid ? option.uuid === value.uuid : option.title === inputValue
      }
      getOptionLabel={(option) => option.title}
      renderOption={(props, option) => {
        return (
          <li
            {...props}
            key={option.uuid || option.title}
            style={{
              display: "block",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontWeight: 600 }}>{option.title}</Typography>
              {!!option.uuid && (
                <Typography variant="caption">source: crunchbase</Typography>
              )}
            </Box>
            {!!option.description && (
              <Typography variant="caption">{option.description}</Typography>
            )}
          </li>
        );
      }}
      onReset={onReset}
      onBlur={() => {
        if (value) return;
        onBlur();
        setInputValue("");
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Startup name"
          required={required}
          error={error}
          helperText={helperText}
        />
      )}
      loading={loading}
    />
  );
};
