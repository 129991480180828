import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { useCreateEventMutation } from "../redux/api/events";
import { userAuthContext } from "../context/userAuthContext";

interface DialogCreateEventProps {
  open: boolean;
  onClose: VoidFunction;
}

export const DialogCreateEvent = ({
  open,
  onClose,
}: DialogCreateEventProps) => {
  const { user } = useContext(userAuthContext);
  const [createEvent] = useCreateEventMutation();

  const [eventTitle, setEventTitle] = useState("");
  const [error, setError] = useState("");

  const handleClose = () => {
    setError("");
    setEventTitle("");
    onClose();
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setEventTitle(e.target.value);

  const handleSubmit = async () => {
    if (!eventTitle) {
      setError("Title should not be an empty value");
      return;
    }
    if (!user?.fund?.id) {
      setError(`There is no fund connected to current user`);
      return;
    }

    setError("");
    try {
      await createEvent({
        title: eventTitle,
        fundId: user.fund.id,
        startupIds: [],
      });
      handleClose();
    } catch (error) {
      setError("Server error");
    }
  };

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Create an Event</DialogTitle>
      <DialogContent sx={{ paddingTop: "10px !important" }}>
        <TextField
          value={eventTitle}
          onChange={handleChange}
          label="Event Title"
          fullWidth
          error={!!error}
          helperText={error}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
