import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Theme,
  useMediaQuery,
} from "@mui/material";

interface DialogAdditionalInformationProps {
  open: boolean;
  onScanQr: VoidFunction;
  onTakePicture: (picture: File) => void;
  onClose: VoidFunction;
}

export const DialogAdditionalInformation = ({
  open,
  onScanQr,
  onTakePicture,
  onClose,
}: DialogAdditionalInformationProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const handleScanQr = () => {
    onScanQr();
    onClose();
  };

  const handleTakePicture = (picture: File) => {
    onTakePicture(picture);
    onClose();
  };

  const content = (
    <>
      <DialogTitle>Choose action</DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleScanQr}
        >
          Scan QR Code
        </Button>
        <Button
          fullWidth
          component="label"
          variant="contained"
          color="secondary"
        >
          Take Picture
          <input
            hidden
            type="file"
            onChange={(e) =>
              e.target.files?.length
                ? handleTakePicture(e.target.files[0])
                : undefined
            }
          />
        </Button>
      </DialogContent>
    </>
  );

  if (!isMobile)
    return (
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        {content}
      </Dialog>
    );

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      {content}
    </Drawer>
  );
};
