import { Box, Collapse, Typography } from "@mui/material";
import { Member } from "../../../@types/api";
import { Close } from "@mui/icons-material";

interface MembersListProps {
  open: boolean;
  members: Member[];
  onClick: (member: Member) => void;
  onRemove: (id: string) => void;
}

export const MembersList = ({
  open,
  members,
  onClick,
  onRemove,
}: MembersListProps) => {
  const handleRemove = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    id: string
  ) => {
    e.stopPropagation();
    onRemove(id);
  };

  return (
    <Collapse in={open}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingLeft: "10px",
        }}
      >
        {members.length ? (
          members.map((member) => (
            <Box
              key={member.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                color: "gray",
                p: 1,
                "&:hover": { backgroundColor: "rgb(240,240,240)" },
              }}
              onClick={() => onClick(member)}
            >
              <Typography variant="subtitle1">
                {member.firstName} {member.lastName}
              </Typography>
              <Close onClick={(e) => handleRemove(e, member.id)} />
            </Box>
          ))
        ) : (
          <Typography sx={{ textAlign: "center" }} variant="subtitle2">
            There are no members yet
          </Typography>
        )}
      </Box>
    </Collapse>
  );
};
