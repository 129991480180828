import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./axiosBaseQuery";

export const api = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL_API || "",
  }),
  endpoints: () => ({}),
  tagTypes: ["StartupsListByFund", "Startup", "EventsListByFund"],
});
