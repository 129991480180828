import axios from "axios";
import { Response } from "../@types/response";
import { CreateFundUserDto, Fund } from "../@types/api";

export const createFund = async (data: CreateFundUserDto) => {
  try {
    const response = await axios.post("/fund", data);
    return response.data as Response<Fund>;
  } catch (error) {
    throw error;
  }
};
