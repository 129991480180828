import { Theme } from "@mui/material";
import { Button } from "./Button";
import { InputLabel } from "./InputLabel";
import { TextField } from "./TextField";
import { Typography } from "./Typography";

export const ComponentsOverrides = (theme: Theme): any => {
  return Object.assign(
    Button(theme),
    InputLabel(theme),
    TextField(theme),
    Typography(theme)
  );
};
