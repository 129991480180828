/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum UserRole {
  Fund = "fund",
  Startup = "startup",
}

export interface Fund {
  id: string;
  name: string;
  linkedIn?: string;
  web?: string;
  phone?: string;
}

export interface UserResponse {
  id: string;
  email?: string;
  photo?: string;
  linkedIn?: string;
  role: UserRole;
  fund?: Fund;
}

export type UpdateUserDto = object;

export interface CreateUserDto {
  id: string;
  email: string;
  role?: "fund" | "startup";
  photo?: string | null;
}

export interface StartupOrganization {
  uuid?: string;
  title: string;
  description?: string;
}

export enum StartupMemberRole {
  Founder = "Founder",
  TeamMember = "Team Member",
  Advisor = "Advisor",
}

export enum StartupMemberAssignment {
  FullTime = "Full time",
  PartTime = "Part time",
  WhenNeeded = "When needed",
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  role: StartupMemberRole;
  shares?: string;
  assignment: StartupMemberAssignment;
}

export interface Traction {
  usersNumber: number;
  newUsersNumberPerMonth: number;
}

export interface CurrencyAmount {
  value: number;
  currency: "EUR" | "USD";
}

export interface FinancialMetrics {
  mrr: CurrencyAmount;
  monthlyBurnRate: CurrencyAmount;
}

export interface EventEntity {
  id: string;
  title: string;
  fundId: string;
  startupIds: string[];
  created: number;
}

export interface VisitCard {
  id: string;
  image: string;
}

export interface StartupResponse {
  id: string;
  organization: StartupOrganization;
  fundId: string;
  web: string;
  email: string;
  pitchDeck: string;
  created: number;
  members: Member[];
  traction: Traction;
  financialMetrics: FinancialMetrics;
  founderFirstName: string;
  founderLastName: string;
  linkedIn: string;
  industry: string;
  market: string;
  teamSize: number | null;
  marketSize: number | null;
  serviceDifferentiation: string;
  businessModel: string;
  notes: string;
  otherReferences: string[];
  event: EventEntity;
  visitCards: VisitCard[];
}

export interface SendLinkDto {
  email: string;
  organizationTitle: string;
  fundName: string;
}

export interface CreateUpdateStartupDto {
  fundId?: string;
  web?: string;
  eventId?: string;
  linkedIn?: string;
  email?: string;
  pitchDeck?: string;
  founderFirstName?: string;
  founderLastName?: string;
  organization?: StartupOrganization;
  industry?: string;
  market?: string;
  teamSize?: number;
  members?: Member[];
  marketSize?: number;
  serviceDifferentiation?: string;
  businessModel?: string;
  notes?: string;
  otherReferences?: string[];
  visitCards?: string[];
  traction?: Traction;
  financialMetrics?: FinancialMetrics;
}

export interface CreateFundUserDto {
  fundName: string;
  fundWeb?: string;
  fundLinkedIn?: string;
  fundPhone?: string;
  userLinkedIn?: string;
}

export interface CrunchbaseIdentifier {
  uuid: string;
  value: string;
  image_id: string;
  permalink: string;
  entity_def_id: string;
}

export interface CrunchbaseEntity {
  facet_ids: any[][];
  identifier: CrunchbaseIdentifier;
  short_description: string;
}

export interface CrunchbaseAutocompletesResponse {
  count: number;
  entities: CrunchbaseEntity[];
}

export interface CrunchbaseOrganizationProperties {
  identifier: CrunchbaseIdentifier;
}

export interface CrunchbaseSocialField {
  value: string;
}

export interface CrunchbaseLocationIdentifier {
  uuid: string;
  value: string;
  permalink: string;
  entity_def_id: string;
  location_type: "city" | "region" | "country" | "continent";
}

export interface CrunchbaseFields {
  linkedin: CrunchbaseSocialField;
  facebook: CrunchbaseSocialField;
  twitter: CrunchbaseSocialField;
  location_identifiers: CrunchbaseLocationIdentifier[];
  website_url: string;
}

export interface CrunchbaseCards {
  fields: CrunchbaseFields;
}

export interface CrunchbaseOrganization {
  properties: CrunchbaseOrganizationProperties;
  cards: CrunchbaseCards;
}

export interface AutocompletesCrunchbaseDto {
  query: string;
  limit?: number;
  collection_ids?: string;
}

export interface AuthResponse {
  user: UserResponse;
  token: string;
}

export interface CreateEventDto {
  title: string;
  fundId: string;
  startupIds: string[];
}

export interface UpdateEventDto {
  title?: string;
  startupIds?: string[];
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title Boosterchance API
 * @version 1.0
 * @contact
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  users = {
    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerCreate
     * @request POST:/users
     * @secure
     */
    usersControllerCreate: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Users
     * @name UsersControllerFindOne
     * @request GET:/users/{id}
     * @secure
     */
    usersControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/users/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  startup = {
    /**
     * No description
     *
     * @tags Startup
     * @name StartupControllerFindAllByFund
     * @request GET:/startup/fund/{id}
     * @secure
     */
    startupControllerFindAllByFund: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/startup/fund/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Startup
     * @name StartupControllerFindOne
     * @request GET:/startup/{id}
     * @secure
     */
    startupControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/startup/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Startup
     * @name StartupControllerCreateUpdate
     * @request PATCH:/startup/{id}
     * @secure
     */
    startupControllerCreateUpdate: (id: string, data: CreateUpdateStartupDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/startup/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Startup
     * @name StartupControllerDeleteStartup
     * @request DELETE:/startup/{id}
     * @secure
     */
    startupControllerDeleteStartup: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/startup/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Startup
     * @name StartupControllerSendLink
     * @request POST:/startup/link/{id}
     * @secure
     */
    startupControllerSendLink: (id: string, data: SendLinkDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/startup/link/${id}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Startup
     * @name StartupControllerFindStartupMeta
     * @request GET:/startup/meta/{id}
     * @secure
     */
    startupControllerFindStartupMeta: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/startup/meta/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  fund = {
    /**
     * No description
     *
     * @tags Fund
     * @name FundControllerCreate
     * @request POST:/fund
     * @secure
     */
    fundControllerCreate: (data: CreateFundUserDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/fund`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fund
     * @name FundControllerFindAll
     * @request GET:/fund
     * @secure
     */
    fundControllerFindAll: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/fund`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Fund
     * @name FundControllerFindOne
     * @request GET:/fund/{id}
     * @secure
     */
    fundControllerFindOne: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/fund/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  crunchbase = {
    /**
     * No description
     *
     * @tags Crunchbase
     * @name CrunchbaseControllerGetAutocompletes
     * @request GET:/crunchbase/autocompletes
     * @secure
     */
    crunchbaseControllerGetAutocompletes: (
      query: {
        query: string;
        limit?: number;
        collection_ids?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/crunchbase/autocompletes`,
        method: "GET",
        query: query,
        secure: true,
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags Auth
     * @name AuthControllerCreate
     * @request POST:/auth
     */
    authControllerCreate: (data: CreateUserDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  events = {
    /**
     * No description
     *
     * @tags Events
     * @name EventsControllerGetAll
     * @request GET:/events/fund/{id}
     * @secure
     */
    eventsControllerGetAll: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/fund/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsControllerGetById
     * @request GET:/events/{id}
     * @secure
     */
    eventsControllerGetById: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/${id}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsControllerUpdate
     * @request PUT:/events/{id}
     * @secure
     */
    eventsControllerUpdate: (id: string, data: UpdateEventDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Events
     * @name EventsControllerCreate
     * @request POST:/events
     * @secure
     */
    eventsControllerCreate: (data: CreateEventDto, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/events`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
