import { useContext, useEffect, useState } from "react";
import { DialogCreateFund } from "../components/DialogCreateFund";
import { userAuthContext } from "../context/userAuthContext";
import { useLocation } from "react-router-dom";
import { UserRole } from "../@types/api";

interface StartupApplicationGuardProps {
  children: React.ReactNode;
}

export const StartupApplicationGuard = ({
  children,
}: StartupApplicationGuardProps) => {
  const { user } = useContext(userAuthContext);
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    if (user && !user.fund?.id && user.role === UserRole.Fund) setOpen(true);
  }, [user, user?.fund?.id, pathname]);

  const handleClose = (_: {}, reason: "backdropClick" | "escapeKeyDown") => {
    if (reason === "backdropClick") return;

    setOpen(false);
  };

  return (
    <>
      {children}
      <DialogCreateFund open={open} onClose={handleClose} />
    </>
  );
};
