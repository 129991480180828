import axios from "axios";
import { Response } from "../@types/response";
import {
  CreateUpdateStartupDto,
  CrunchbaseOrganization,
  SendLinkDto,
  StartupResponse,
} from "../@types/api";

interface GetStartupDataRequest {
  id: string;
}

interface GetStartupListByFundRequest {
  id: string;
}

interface UpdateStartupRequest {
  id: string;
  data: CreateUpdateStartupDto;
}

interface SendStartupEmailrequest {
  id: string;
  data: SendLinkDto;
}

export const getStartup = async ({ id }: GetStartupDataRequest) => {
  try {
    const response = await axios.get(`/startup/${id}`);
    return response.data as Response<StartupResponse>;
  } catch (error) {
    throw error;
  }
};

export const getStartupMeta = async ({ id }: GetStartupDataRequest) => {
  try {
    const response = await axios.get(`/startup/meta/${id}`);
    return response.data as Response<CrunchbaseOrganization>;
  } catch (error) {
    throw error;
  }
};

export const createUpdateStartup = async ({
  id,
  data,
}: UpdateStartupRequest) => {
  try {
    const response = await axios.patch(`/startup/${id}`, data);
    return response.data as Response<StartupResponse>;
  } catch (error) {
    throw error;
  }
};

export const sendStartupEmail = async ({
  id,
  data,
}: SendStartupEmailrequest) => {
  try {
    const response = await axios.post(`/startup/link/${id}`, data);
    return response.data as Response<null>;
  } catch (error) {
    throw error;
  }
};

export const getStartupListByFund = async ({
  id,
}: GetStartupListByFundRequest) => {
  try {
    const response = await axios.get(`/startup/fund/${id}`);
    return response.data as Response<StartupResponse[]>;
  } catch (error) {
    throw error;
  }
};
