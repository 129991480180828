import { useCallback, useState } from "react";
import { getCrunchbaseAutocompletes } from "../../../api/crunchbase";
import { toast } from "react-toastify";
import { StartupOrganization } from "../../../@types/api";
import { debounce } from "lodash";

export const useGetStartupOptions = () => {
  const [options, setOptions] = useState<StartupOrganization[]>([]);
  const [loading, setLoading] = useState(false);

  const request = useCallback(async (query: string) => {
    setLoading(true);
    if (!query) {
      setOptions([]);
      setLoading(false);
      return;
    }

    try {
      const { data } = await getCrunchbaseAutocompletes({ query });
      setOptions(
        data.entities.map((entity) => ({
          uuid: entity.identifier.uuid,
          title: entity.identifier.value,
          description: entity.short_description,
        }))
      );
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Server Error");
      setOptions([]);
      setLoading(false);
    }
  }, []);

  const handleInputChangeStartupName = debounce(async (value: string) => {
    await request(value);
  }, 300);

  const reset = () => {
    setOptions([]);
    setLoading(false);
  };

  return { options, loading, handleInputChangeStartupName, reset, request };
};
