import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { toast } from "react-toastify";

interface AxiosBaseQueryProps {
  baseUrl: string;
}

export const axiosBaseQuery =
  (
    { baseUrl }: AxiosBaseQueryProps = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
      withSuccessMessage?: boolean;
      withoutErrorMessage?: boolean;
    },
    unknown,
    unknown
  > =>
  async ({
    url,
    method = "GET",
    data,
    params,
    headers,
    withSuccessMessage,
    withoutErrorMessage,
  }) => {
    try {
      const result = await axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      });
      withSuccessMessage && toast.success(result.data.message);

      return { data: result.data.data };
    } catch (axiosError) {
      const err = axiosError as AxiosError<any>;
      err.response?.data?.message &&
        !withoutErrorMessage &&
        toast.error(err.response?.data?.message);
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
