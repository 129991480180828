import { useContext, useMemo } from "react";
import { userAuthContext } from "../../context/userAuthContext";
import { Loading } from "../../uiKit/Loading";
import { StartupItem } from "./StartupItem";
import { Box, Paper, Typography } from "@mui/material";
import { useGetStartupsListByFundQuery } from "../../redux/api/startup";

export const StartupList = () => {
  const { user } = useContext(userAuthContext);
  const { data: startups, isLoading } = useGetStartupsListByFundQuery(
    user?.fund?.id || "",
    { skip: !user?.fund?.id }
  );

  const sortedStartups = useMemo(() => {
    const startupsCopy = startups ? [...startups] : [];
    return startupsCopy.sort((a, b) => b.created - a.created);
  }, [startups]);

  return (
    <Paper
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
      }}
      component={Box}
    >
      {!sortedStartups?.length && !isLoading && (
        <Typography sx={{ padding: "20px", textAlign: "center" }}>
          There are no startups now
        </Typography>
      )}
      {!isLoading && startups ? (
        sortedStartups.map((startup, index) => (
          <StartupItem key={index} startup={startup} />
        ))
      ) : (
        <Loading />
      )}
    </Paper>
  );
};
