import { useCallback, useRef, useState } from "react";
import { getFileFromUrl } from "../../../utils/files";
import { toast } from "react-toastify";

export const usePitchDeck = () => {
  const [pitchDeck, setPitchDeck] = useState<File | null>(null);
  const pitchDeckRef = useRef<HTMLInputElement>(null);

  const preload = useCallback(async (url: string, id: string) => {
    try {
      const file = await getFileFromUrl(url, "application/pdf");

      if (!file) return;
      const newFile = new File([file], `${id}.pdf`, { type: file.type });
      setPitchDeck(newFile);
    } catch (error) {
      toast.error("Loading file error");
      throw error;
    }
  }, []);

  return { pitchDeck, pitchDeckRef, preload };
};
