import { Dialog } from "@mui/material";
import { QrScanner } from "@yudiel/react-qr-scanner";

interface DialogQrScannerProps {
  open: boolean;
  onClose: VoidFunction;
  onDecode: (result: string) => void;
}

export const DialogQrScanner = ({
  open,
  onClose,
  onDecode,
}: DialogQrScannerProps) => {
  const handleDecode = (result: string) => {
    onDecode(result);
    onClose();
  };
  const handleError = (error: any) => {
    console.log(error);
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <QrScanner
        onDecode={handleDecode}
        onError={handleError}
        scanDelay={1000}
      />
    </Dialog>
  );
};
