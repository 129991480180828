import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Router } from "./routes";
import { ThemeProvider } from "./theme";
import { userAuthContext } from "./context/userAuthContext";
import { useContext, useEffect } from "react";
import { Loading } from "./uiKit/Loading";
import { useAppDispatch } from "./redux/store";
import { loadDefaultEventFromLS } from "./redux/features/events";

const App = () => {
  const dispatch = useAppDispatch();
  const { authLoading } = useContext(userAuthContext);

  useEffect(() => {
    dispatch(loadDefaultEventFromLS());
  }, [dispatch]);

  return (
    <ThemeProvider>
      {authLoading ? <Loading /> : <Router />}
      <ToastContainer position="bottom-right" autoClose={3000} newestOnTop />
    </ThemeProvider>
  );
};

export default App;
