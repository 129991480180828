import { useContext, useState } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { userAuthContext } from "../context/userAuthContext";
import { createFund } from "../api/fund";
import { toast } from "react-toastify";

interface DialogCreateFundProps {
  open: boolean;
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

const CreateFundValidationSchema = Yup.object().shape({
  fundName: Yup.string().required("Fund name is required"),
  fundLinkedIn: Yup.string(),
  fundWeb: Yup.string(),
  fundPhone: Yup.string(),
  userLinkedIn: Yup.string(),
});

interface CreateFundForm {
  fundName: string;
  fundLinkedIn: string | undefined;
  fundWeb: string | undefined;
  fundPhone: string | undefined;
  userLinkedIn: string | undefined;
}

export const DialogCreateFund = ({ open, onClose }: DialogCreateFundProps) => {
  const { user, setUser } = useContext(userAuthContext);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<CreateFundForm>({
    resolver: yupResolver(CreateFundValidationSchema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async (values: CreateFundForm) => {
    if (!user || !user.id) return;

    try {
      setLoading(true);
      const { data: fund } = await createFund(values);
      setUser({ ...user, fund });
      setLoading(false);
      onClose({}, "escapeKeyDown");
    } catch (error: any) {
      toast.error(error.response.data?.message || "Server Error");
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Create New Fund</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            paddingTop: "10px !important",
          }}
        >
          <Controller
            name="fundName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Fund name"
                error={!!errors.fundName}
                helperText={errors.fundName?.message}
                required
              />
            )}
            defaultValue=""
          />
          <Controller
            name="fundLinkedIn"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Fund linkedIn"
                error={!!errors.fundLinkedIn}
                helperText={errors.fundLinkedIn?.message}
              />
            )}
            defaultValue=""
          />
          <Controller
            name="fundWeb"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Fund web"
                error={!!errors.fundWeb}
                helperText={errors.fundWeb?.message}
              />
            )}
            defaultValue=""
          />
          <Controller
            name="fundPhone"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Fund phone"
                error={!!errors.fundPhone}
                helperText={errors.fundPhone?.message}
              />
            )}
            defaultValue=""
          />
          <Controller
            name="userLinkedIn"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="User linkedIn"
                error={!!errors.userLinkedIn}
                helperText={errors.userLinkedIn?.message}
              />
            )}
            defaultValue=""
          />
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" loading={loading} variant="contained">
            Confirm
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
