import {
  Box,
  Typography,
  TextField,
  IconButton,
  TextareaAutosize,
  Stack,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useContext, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";
import { userAuthContext } from "../../context/userAuthContext";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import QRCode from "react-qr-code";
import { generatePersonalLink } from "./helpers";
import { useGetStartupOptions } from "../../components/Startup/StartupNameAutocomplete/useGetStartupOptions";
import { StartupNameAutocomplete } from "../../components/Startup/StartupNameAutocomplete";
import { OrganizationSchema } from "../../@types/startup";
import { EventEntity, StartupOrganization } from "../../@types/api";
import { OpenInNew, Send, QrCodeScanner, CameraAlt } from "@mui/icons-material";
import {
  OtherReference,
  OtherReferenceValidationSchema,
  OtherReferencesList,
} from "../../components/Startup/OtherReferencesList";
import {
  VisitCard,
  VisitCardsList,
} from "../../components/Startup/VisitCardsList";
import { DialogQrScanner } from "../../components/DialogQrScanner";
import { uploadFiles } from "../../firebase-services/uploadFiles";
import { storagePaths } from "../../firebase-config/storage";
import { pathLinks } from "../../routes/paths";
// import { SelectEvent } from "./components/SelectEvent";
import {
  useCreateUpdateStartupMutation,
  useGetStartupQuery,
} from "../../redux/api/startup";
import { getStartupMeta, sendStartupEmail } from "../../api/startup";
import { debounce } from "lodash";
import { useAppSelector } from "../../redux/store";
import { selectDefaultEvent } from "../../redux/selectors/events";
import { AutocompleteEvent } from "../../components/Events/AutocompleteEvent";

const CreateStartupValidationSchema = Yup.object().shape({
  event: Yup.mixed(),
  founderFirstName: Yup.string().trim(),
  founderLastName: Yup.string().trim(),
  fundName: Yup.string().trim().required("Fund name is required"),
  organization: OrganizationSchema.required(),
  linkedIn: Yup.string().trim(),
  web: Yup.string().trim().optional(),
  email: Yup.string().trim().email(),
  notes: Yup.string().trim(),
  otherReferences: Yup.array().of(OtherReferenceValidationSchema).required(),
  visitCards: Yup.mixed(),
  accept: Yup.boolean().oneOf([true], "Should be checked").required(),
});

export interface CreateStartupValues {
  event: EventEntity | undefined | null;
  founderFirstName: string | undefined;
  founderLastName: string | undefined;
  fundName: string;
  organization: StartupOrganization;
  linkedIn: string | undefined;
  web: string | undefined;
  email: string | undefined;
  notes: string | undefined;
  otherReferences: OtherReference[];
  visitCards: VisitCard[];
  accept: boolean;
}

const initialValues: CreateStartupValues = {
  event: null,
  founderFirstName: "",
  founderLastName: "",
  fundName: "",
  organization: {
    uuid: "",
    title: "",
    description: "",
  },
  linkedIn: "",
  web: "",
  email: "",
  notes: "",
  otherReferences: [],
  visitCards: [],
  accept: false,
};

export const CreateStartup = () => {
  const {
    control,
    setValue,
    reset,
    resetField,
    watch,
    formState: { errors },
  } = useForm<CreateStartupValues>({
    resolver: yupResolver(CreateStartupValidationSchema),
    defaultValues: initialValues,
  });
  const defaultEvent = useAppSelector(selectDefaultEvent);

  const {
    options,
    handleInputChangeStartupName,
    loading: fetching,
    reset: resetOptions,
  } = useGetStartupOptions();
  const [visitCardsKey, setVisitCardsKey] = useState(Date.now());

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useContext(userAuthContext);
  const { id } = useParams();

  const { data: startup, isFetching } = useGetStartupQuery({
    id: id || "",
    withoutErrorMessage: true,
  });
  const [createUpdateStartup] = useCreateUpdateStartupMutation();

  const values = watch();
  const linkedIn = values.linkedIn;

  const fund = searchParams.get("fund");

  const [isEmailChangedManually, setIsEmailChangedManually] = useState(false);
  const [openQrScanner, setOpenQrScanner] = useState(false);

  const handleSubmitQrScanner = async (result: string) => {
    try {
      const id = v4();
      const otherReferences = [
        ...values.otherReferences,
        { id, title: result },
      ];
      await updateForm({ ...values, otherReferences });
      setValue("otherReferences", otherReferences);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteOtherReference = (id: string) => {
    const otherReferences = values.otherReferences.filter(
      (reference) => reference.id !== id
    );
    updateForm({ ...values, otherReferences });
    setValue("otherReferences", otherReferences);
  };
  const handleTakePicture = async (picture: File) => {
    try {
      const id = v4();
      const visitCards = [...values.visitCards, { id, image: picture }];
      const imageToUpload = [{ id, file: picture }] as {
        id: string;
        file: File;
      }[];
      await uploadFiles(imageToUpload, storagePaths.visitCards);
      await updateForm({ ...values, visitCards });
      setValue("visitCards", visitCards);
      setVisitCardsKey(Date.now());
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteVisitCard = (id: string) => {
    const visitCards = values.visitCards.filter(
      (visitCard) => visitCard.id !== id
    );
    updateForm({ ...values, visitCards });
    setValue("visitCards", visitCards);
  };

  const getAdditionalInformation = (values: CreateStartupValues) => {
    const otherReferences = values.otherReferences.map(
      (reference) => reference.title
    );
    const visitCardIds = values.visitCards.map((visitCard) => visitCard.id);

    return { otherReferences, visitCardIds };
  };

  const handleChangeStartupNameValue = async (value: StartupOrganization) => {
    if (!id || !user?.fund?.id) return;

    try {
      const data = {
        ...values,
        organization: value,
      };

      if (value.uuid) {
        const { data: meta } = await getStartupMeta({ id: value.uuid });
        const web = meta.cards.fields?.website_url || data.web;
        const linkedIn = meta.cards.fields?.linkedin?.value || data.linkedIn;

        data.web = web;
        data.linkedIn = linkedIn;

        setValue("web", web);
        setValue("linkedIn", linkedIn);
      }

      await updateForm(data);
    } catch (error) {
      throw error;
    }
  };

  const updateForm = useMemo(
    () =>
      debounce(async (values: CreateStartupValues) => {
        if (!id) return;

        const { event, ...data } = values;
        const { otherReferences, visitCardIds } =
          getAdditionalInformation(values);

        createUpdateStartup({
          id,
          data: {
            ...data,
            otherReferences,
            visitCards: visitCardIds,
            eventId: event?.id || "",
          },
        });
      }, 400),
    [id, createUpdateStartup]
  );

  const handleSendLink = () => {
    if (!id || !user?.fund?.name) {
      toast.error("Incorrect startup id or fund name");
      return;
    }
    if (!values?.organization?.title) {
      toast.error("You should choose organization first");
      return;
    }

    if (!values?.email) {
      toast.error("You should enter email first");
      return;
    }

    const data = {
      email: values.email,
      fundName: user.fund.name,
      organizationTitle: values.organization.title,
    };

    sendStartupEmail({
      id,
      data,
    });

    toast.success("Personal link was sent successfully on current email");
  };

  const handleSubmit = () => {
    if (!id) return;
    navigate(pathLinks.startupDetails(id));
  };

  const handlePrefillEmail = (
    founderFirstName: string | undefined,
    organizationTitle: string | undefined
  ) => {
    if (founderFirstName && organizationTitle && !isEmailChangedManually) {
      const domain = organizationTitle.toLowerCase().replace(/\s/g, "");

      setValue("email", `${founderFirstName.toLowerCase()}@${domain}.com`);
    }
  };

  // Create new startup on boot
  useEffect(() => {
    if (!id || isFetching || (!isFetching && startup)) return;

    createUpdateStartup({ id, data: { eventId: defaultEvent?.id } });
  }, [id, startup, createUpdateStartup, isFetching, defaultEvent?.id]);

  // Preload startup by id
  useEffect(() => {
    const otherReferences = startup?.otherReferences || [];
    const otherReferencesWithId = otherReferences.map((reference) => ({
      id: v4(),
      title: reference,
    }));

    reset({
      event: startup?.event || defaultEvent,
      organization: startup?.organization || {
        title: "",
        uuid: "",
        description: "",
      },
      fundName: user?.fund?.name || fund || "",
      web: startup?.web || "",
      email: startup?.email || "",
      founderFirstName: startup?.founderFirstName || "",
      founderLastName: startup?.founderLastName || "",
      linkedIn: startup?.linkedIn || "",
      notes: startup?.notes || "",
      otherReferences: otherReferencesWithId,
      visitCards: startup?.visitCards || [],
    });
  }, [startup, reset, fund, user?.fund?.name, defaultEvent]);

  return (
    <form
      style={{
        marginTop: "50px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          margin: {
            xs: "0 auto",
            sm: "auto",
          },
          px: "24px",
        }}
      >
        <Typography sx={{ textAlign: "center" }} variant="h5">
          Startup Onboarding
        </Typography>
        <Typography
          sx={{ textAlign: "center", color: "rgb(76, 76, 76)" }}
          variant="subtitle1"
        >
          Startup founder will get personal registration link to fill further
          details
        </Typography>
        <AutocompleteEvent
          sx={{ maxWidth: "500px", margin: "auto" }}
          value={values.event}
          onReset={() => {
            setValue("event", null);
            updateForm({ ...values, event: null });
          }}
          onChange={(event) => {
            setValue("event", event);
            updateForm({ ...values, event });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "24px",
          mt: 4,
          p: 3,
        }}
      >
        <Stack direction="row" gap={2}>
          <IconButton color="secondary" onClick={() => setOpenQrScanner(true)}>
            <QrCodeScanner sx={{ fontSize: "40px" }} />
          </IconButton>
          <IconButton component="label" color="secondary">
            <CameraAlt sx={{ fontSize: "40px" }} />
            <input
              key={visitCardsKey}
              hidden
              type="file"
              onChange={(e) =>
                e.target.files?.length
                  ? handleTakePicture(e.target.files[0])
                  : undefined
              }
            />
          </IconButton>
        </Stack>
        {!!values.otherReferences.length && (
          <OtherReferencesList
            references={values.otherReferences}
            onDelete={handleDeleteOtherReference}
          />
        )}
        {!!values.visitCards.length && (
          <VisitCardsList
            visitCards={values.visitCards}
            onDelete={handleDeleteVisitCard}
          />
        )}
        <Controller
          name="fundName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              label="Fund name"
              error={!!errors.fundName}
              helperText={errors.fundName?.message}
              disabled
              required
            />
          )}
          defaultValue=""
        />
        <Controller
          name="founderFirstName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              onChange={(e) => {
                field.onChange(e);
                handlePrefillEmail(e.target.value, values.organization.title);
                updateForm({ ...values, [e.target.name]: e.target.value });
              }}
              label="Founder first name"
              error={!!errors.founderFirstName}
              helperText={errors.founderFirstName?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="founderLastName"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              onChange={(e) => {
                field.onChange(e);
                updateForm({ ...values, [e.target.name]: e.target.value });
              }}
              label="Founder last name"
              error={!!errors.founderLastName}
              helperText={errors.founderLastName?.message}
            />
          )}
          defaultValue=""
        />
        <Controller
          name="organization"
          control={control}
          render={({ field }) => (
            <StartupNameAutocomplete
              options={options}
              loading={fetching}
              error={!!errors.organization?.title}
              helperText={errors.organization?.title?.message}
              value={field.value}
              onInputChange={handleInputChangeStartupName}
              onBlur={resetOptions}
              onReset={() => resetField("organization")}
              onChange={(value: StartupOrganization) => {
                handleChangeStartupNameValue(value);
                handlePrefillEmail(values.founderFirstName, value.title);
                field.onChange(value);
              }}
            />
          )}
          defaultValue={undefined}
        />
        {!!watch("organization") &&
          !!watch("organization.title") &&
          !!user &&
          id && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <QRCode value={generatePersonalLink(id)} />
            </Box>
          )}
        <Stack direction="row" gap="10px">
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                label="Startup email"
                error={!!errors.email}
                onChange={(e) => {
                  field.onChange(e);
                  updateForm({ ...values, [e.target.name]: e.target.value });
                  setIsEmailChangedManually(true);
                }}
                helperText={errors.email?.message}
              />
            )}
            defaultValue=""
          />
          {!!values.email && (
            <Tooltip title="Send personal link to current email">
              <IconButton onClick={handleSendLink} sx={{ width: "56px" }}>
                <Send />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Controller
          name="web"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              onChange={(e) => {
                field.onChange(e);
                updateForm({ ...values, [e.target.name]: e.target.value });
              }}
              label="Startup web"
            />
          )}
          defaultValue=""
        />
        <Box sx={{ display: "flex" }}>
          <Controller
            name="linkedIn"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  updateForm({ ...values, [e.target.name]: e.target.value });
                }}
                label="Startup linkedIn"
              />
            )}
            defaultValue=""
          />
          {linkedIn && (
            <IconButton
              sx={{ minWidth: "56px" }}
              onClick={() => window.open(linkedIn)}
            >
              <OpenInNew />
            </IconButton>
          )}
        </Box>
        <Controller
          name="notes"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              {...field}
              onChange={(e) => {
                field.onChange(e);
                updateForm({ ...values, [e.target.name]: e.target.value });
              }}
              multiline
              label="Notes"
              InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: {
                  style: {
                    resize: "vertical",
                    minHeight: "60px",
                  },
                },
              }}
            />
          )}
          defaultValue=""
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <LoadingButton onClick={handleSubmit} variant="contained">
            Go to details
          </LoadingButton>
        </Box>
      </Box>
      <DialogQrScanner
        open={openQrScanner}
        onClose={() => setOpenQrScanner(false)}
        onDecode={handleSubmitQrScanner}
      />
    </form>
  );
};
